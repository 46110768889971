<template>
  <div>
     <!-- Breadcrumb Section Start -->
    <div class="mt-5">
    <img src="assets\images\home\contacts-hs.jpg" alt="">
        <div class="container">
            <div class="row">
                <div class="col-12">

                    <!-- Breadcrumb wrapper Start -->
                    <div class="breadcrumb-wrapper">
                        <h2 class="title text-white" data-aos="fade-up" data-aos-duration="1100">Contact Us</h2>

                        <ul class="breadcrumb-menu" data-aos="fade-up" data-aos-duration="1500">
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>
                                <span>Contact</span>
                            </li>
                        </ul>
                    </div>
                    <!-- Breadcrumb wrapper End -->

                </div>
            </div>
        </div>
    </div>
    <!-- Breadcrumb Section End -->

    <!-- Contact Wrapper Start -->
    <div class="section section-margin">
        <div class="container">
            <div class="row align-items-center m-b-n40">
                <div class="col-lg-5 m-b-40">

                    <div class="contact-content">

                        <!-- Section Title Start -->
                        <div class="section-title">
                            <h2 class="title">You can Communicate with us...</h2>
                            <p></p>
                        </div>
                        <!-- Section Title End -->

                        <!-- Single Contact Start -->
                        <div class="single-contact" data-aos="fade-up" data-aos-duration="1200">
                            <span class="title">Address</span>
                            <p>207/208, Citadel Mindspace,Airport Rd, opp. Kadri Heights Apartment, Yeyyadi,Mangaluru
                                Karnataka 575008</p>
                        </div>
                        <!-- Single Contact End -->

                        <!-- Single Contact Start -->
                        <div class="single-contact" data-aos="fade-up" data-aos-duration="1400">
                            <span class="title">Phone</span>
                            <a href="tel:123-123-456-789">+91 8243500523</a>
                            <!-- <a href="tel:123-123-456-789">+00 123 456 789</a> -->
                        </div>
                        <!-- Single Contact End -->

                        <!-- Single Contact Start -->
                        <div class="single-contact" data-aos="fade-up" data-aos-duration="1600">
                            <span class="title">Web</span>
                            <!-- <a href="mailto:www.intellogicsoftware.com">www.intellogicsoftware.com</a> -->
                            <a href="mailto:info@intellogicsoftware.com">info@intellhrms.com</a>
                        </div>
                        <!-- Single Contact End -->

                    </div>

                </div>
                <div class="col-lg-7 m-b-40">

                    <!-- Contact Form Wrapper Start -->
                    <div class="contact-form-wrapper contact-form">
                        <!-- Section Title Start -->
                        <div class="section-title p-b-15">
                            <h4 class="title">Send us a Message</h4>
                            <!-- <span></span> -->
                        </div>
                        <!-- Section Title End -->
                        <form @submit.prevent="submitForm">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">

                                        <div class="col-md-12" data-aos="fade-up" data-aos-duration="1400">
                                            <div class="input-area m-b-20">
                                                <input class="input-item" type="text" v-model="name" placeholder="Your Name *"
                                                    name="name" required>
                                            </div>
                                        </div>

                                        <div class="col-md-12" data-aos="fade-up" data-aos-duration="1600">
                                            <div class="input-area m-b-20">
                                                <input class="input-item" type="email" v-model="email" placeholder="Email *"
                                                    name="email" required>
                                                     <p class="error-message" v-if="isInvalidEmail">Invalid email address.</p>
                                            </div>
                                           
                                        </div>

                                        <div class="col-12" data-aos="fade-up" data-aos-duration="1800">
                                            <div class="input-area m-b-20">
                                                <input class="input-item" type="text" v-model="subject" placeholder="Subject *"
                                                    name="subject" required>
                                            </div>
                                        </div>

                                        <div class="col-12" data-aos="fade-up" data-aos-duration="2000">
                                            <div class="input-area m-b-40">
                                                <textarea cols="30" rows="5" class="textarea-item" v-model="message" name="message"
                                                    placeholder="Message" required></textarea>
                                            </div>
                                        </div>

                                      <div v-if="isSending">
                                         <p class="email-sending-mssg">Sending...</p>
                                         <!-- Add your desired animations here -->
                                          </div>
                                      <div v-else-if="isSent">
                                         <p class="email-sucess-mssg">Email sent successfully!</p>
                                         </div>
                                         <div v-else-if="sendingFailed">
                                         <p class="email-error-mssg">Email sending failed!</p>
                                         </div>
                                        <div v-else  v-show="showSubmitButton" class="col-12" data-aos="fade-up" data-aos-duration="2200">
                                            <button type="submit" id="submit" name="submit"
                                                class="btn btn-dark btn-hover-primary">Send Now</button>
                                        </div>
                                        <p class="col-8 form-message mb-0"></p>

                                    </div>
                                </div>
                            </div>
                        </form>
                        <p class="form-messege"></p>
                    </div>
                    <!-- Contact Form Wrapper End -->

                </div>
            </div>
        </div>
    </div>
    <!-- Contact Wrapper End -->
  </div>
</template>

<script>
import axios from 'axios'; 
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
     data() {
    return {
      email: "",
      name: "",
      subject: "",
      message: "",
      isSending: false,
      isSent: false,
      sendingFailed: false,
      showSubmitButton: true
      // token: process.env.INTELLHRMS_API_KEY,
    };
  },
  mounted() {
    AOS.init({
      duration: 1100 // Set the default duration for all elements with data-aos-duration attribute
    });
  },
   computed: {
    isInvalidEmail() {
      return !this.isValidEmail(this.email) && this.email !== '';
    },
  },

   methods: {
     isValidEmail(email) {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

   submitForm() {
   
    const token = process.env.VUE_APP_INTELLHRMS_API_KEY;
  
    if (this.isValidEmail(this.email)){
        // const token = '41|AlvuRnUd6p8gvYkMc6NfLowYJfUTYwPem8z3fijL';
     this.showSubmitButton = false;
     this.isSending = true;
    this.isSending = true;
    axios.post('https://demo.intellhrms.com/api/contact-us', {
       
          name: this.name,
          email: this.email,
          subject: this.subject,
          message: this.message
    },{
    headers: {
          Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          })
      .then(response => {
         this.isSending = false;
        this.isSent = true;

        setTimeout(() => {
        // Once the email is sent (simulated after 2 seconds), update the flags
        this.isSent = false;
        this.showSubmitButton = true;
        // Reset the form if needed
         this.clearForm();
      }, 1000);
       console.log('Email sent successfully',response);
      })
      .catch(error => {
        this.isSending = false;
        this.sendingFailed = true;

        setTimeout(() => {
        // Once the email is sent (simulated after 2 seconds), update the flags
        this.sendingFailed = false;
        this.showSubmitButton = true;
        // Reset the form if needed
         this.clearForm();
      }, 1000);
       
        console.error('Error sending email:', error);
      });
    }
  },

  clearForm(){
    this.name = "";
    this.email = "";
    this.subject = "";
    this.message = "";
  }

}
};
</script>

<style scoped>
 .error-message {
  color:#ff3333;
  font-weight: 430;
  font-size: 13px;
  text-align: start;
  margin-top: 5px;
  }
  .email-sucess-mssg {
    color: green;
    text-align: center;
    font-family: sans-serif;
  }
  .email-sending-mssg {
    color: blue;
    text-align: center;
    font-family: sans-serif;
  }
  .email-error-mssg {
    color: red;
    text-align: center;
    font-family: sans-serif;
  }
</style>