// import Vue from 'vue';
// import VueRouter from 'vue-router';

// import AboutPage from './pages/AboutPage.vue';

// Vue.use(VueRouter);

// const routes = [
//   { path: '/about', component: AboutPage },
// ];

// const router = new VueRouter({
//   mode: 'history',
//   routes,
// });

// export default router;
import { createRouter, createWebHistory  } from 'vue-router';
// import Vue from 'vue';
// import VueRouter from 'vue-router';

// Import your components for each route

import AboutPage from  './pages/AboutPage.vue';
import HomePage from './pages/HomePage.vue';
import PricingPage from './pages/PricingPage.vue';
import ContactPage from './pages/ContactPage.vue';
import AssetManagement from './pages/AssetManagement.vue';
import AttendanceManagement from './pages/AttendanceManagement.vue';
import EmployeeManagement from './pages/EmployeeManagement.vue';
import EmployeeOnboarding from './pages/EmployeeOnboarding.vue';
import LeaveManagement from './pages/LeaveManagement.vue';
import LoginPage from './pages/LoginPage.vue';
import PayrollManagement from './pages/PayrollManagement.vue';
import PerformanceAppraisal from './pages/PerformanceAppraisal.vue';
import ProjectManagement from './pages/ProjectManagement.vue';
import RecruitmentPage from './pages/RecruitmentPage.vue';
import SettingsPage from './pages/SettingsPage.vue';
import MobileApp from './pages/MobileApp.vue';
import LiveLocationTracking from './pages/LiveLocationTracking.vue';


// import Contact from './components/Contact.vue';

// Vue.use(VueRouter);

const routes = [
  { path: '/', name: 'HomePage', component: HomePage },
  { path: '/about', name: 'AboutPage', component: AboutPage },
  { path: '/pricing', name: 'PricingPage', component: PricingPage },
  { path: '/contact', name: 'ContactPage', component: ContactPage },
  { path: '/asset-management', name: 'AssetManagement', component: AssetManagement },
  { path: '/attendance-management', name: 'AttendanceManagement', component: AttendanceManagement },
  { path: '/employee-management', name: 'EmployeeManagement', component: EmployeeManagement },
  { path: '/employee-onboarding', name: 'EmployeeOnboarding', component: EmployeeOnboarding },
  { path: '/leave-management', name: 'LeaveManagement', component: LeaveManagement },
  { path: '/login', name: 'LoginPage', component: LoginPage },
  { path: '/payroll-management', name: 'PayrollManagement', component: PayrollManagement },
  { path: '/performance-appraisal', name: 'PerformanceAppraisal', component: PerformanceAppraisal },
  { path: '/project-management', name: 'ProjectManagement', component: ProjectManagement },
  { path: '/recruitment', name: 'RecruitmentPage', component: RecruitmentPage },
  { path: '/settings', name: 'SettingsPage', component: SettingsPage },
  { path: '/mobile-app', name: 'MobileApp', component: MobileApp },
  { path: '/live-location-tracking', name: 'LiveLocationTracking', component: LiveLocationTracking },
];

const router = new createRouter({
  history: createWebHistory(),
  routes,
  "navigationFallback": {
    "rewrite": "/index.html",
    "exclude": ["/images/*.{png,jpg,gif}", "/css/*"]
  }
});

export default router;
