<template>
   <div>
     <!-- Breadcrumb Section Start -->
    <div class="section product-breadcrumb-section product-container">
        <div class="container-product">
            <div class="row">
                <div class="col-lg-6">

                    <!-- Breadcrumb wrapper Start -->
                    <div class="breadcrumb-wrapper1 mb-4">
                        <h2 class="title" data-aos="fade-up" data-aos-duration="1100">Live Location Tracking</h2>
                        <!-- <div class="text-dark" style="font-weight:300" data-aos="fade-up" data-aos-duration="1100" class="about-content mw-100"> -->
                        <span></span>
                        <ul class="prod-main-con text-dark content-header-list about-content mw-100" data-aos="fade-up" data-aos-duration="1100"
                           >
                            <li><strong> Welcome to the future of efficient and streamlined operations! In today's fast-paced world, staying ahead requires real-time insights and data-driven decision-making. Our Live Location Tracking solution is designed to empower businesses by providing a comprehensive and accurate view of the location of assets, vehicles, and personnel. Whether you're in logistics, field services, or any industry that relies on mobility, our cutting-edge technology ensures that you are always in control.</strong></li>
                            <!-- <li><strong> Payroll Process, Payslip Generation, Lock Payroll, Arrears, LOP/LOP Reversal/Salary Advance.</strong></li>
                            <li><strong> Final Settlement for resigned employees</strong></li>
                            <li><strong>IT Declaration - Employee can declare the IT declaration in April and February once the account department enables it.</strong></li>
                            <li><strong> IT Declaration Approval - Accounts Head or Accounts Executive Responsible for the IT Declaration Will be approved after reviewing the details.</strong></li> -->
                        </ul>
                        <!-- </div> -->
                        <!-- <ul class="breadcrumb-menu" data-aos="fade-up" data-aos-duration="1500">
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>
                                <span>Service</span>
                            </li>
                        </ul> -->
                    </div>
                    <!-- Breadcrumb wrapper End -->

                </div>
                <div class="col-lg-6">
                    <img src="assets\images\5375438_Dark-map.jpg">
                </div>
            </div>
        </div>
    </div>
    <!-- Breadcrumb Section End -->

      <!-- About Section Start -->
    <div class="section section-margin">
        <div class="container">
            <div class="row">
                <!-- <div class="col-md-5" >
        <img src="assets/images/products-images/leave application.png" width="500" height="400">
        </div> -->
                <div class="col-md-7">
                    <div class="about-content mw-100">
                        <h2 class="title">Live Location Tracking</h2>
                        <span></span>
                        <p class="prod-sub-con">
                           <!-- "Empower Your Business with Real-Time Live Location Tracking"<br> -->


Welcome to the future of efficient and streamlined operations! In today's fast-paced world, staying ahead requires real-time insights and data-driven decision-making. Our Live Location Tracking solution is designed to empower businesses by providing a comprehensive and accurate view of the location of assets, vehicles, and personnel. Whether you're in logistics, field services, or any industry that relies on mobility, our cutting-edge technology ensures that you are always in control. <br>



Gain instant insights into the live location of your assets or workforce. Our advanced tracking system provides a continuous stream of location data, allowing you to make informed decisions on the fly. <br>


Tailor the mapping interface to meet your specific needs. Highlight important zones, set geofences, and customize map layers to visualize data in a way that makes sense for your business. <br>

<!-- Geofencing and Alerts:
Set up virtual boundaries and receive instant notifications when assets or personnel enter or exit predefined areas. This feature is invaluable for enhancing security, optimizing routes, and improving overall operational efficiency. <br> -->

<!-- Historical Tracking:
Access historical location data to analyze trends, optimize routes, and identify areas for improvement. This tool is instrumental in enhancing overall efficiency and making data-backed decisions for your business. <br>

Integration Capabilities:
Seamlessly integrate our Live Location Tracking solution with your existing systems, such as fleet management software or CRM platforms. This ensures a unified approach to data management across your organization. <br>

Mobile Accessibility:
Stay connected on the go with our mobile-friendly platform. Access live location data, receive alerts, and manage your assets from the palm of your hand, providing unparalleled flexibility for today's dynamic business environment. <br> -->

<!-- Benefits: <br>

Enhanced Operational Efficiency:
Streamline your processes and optimize resource allocation with real-time location insights, leading to improved overall efficiency.<br> -->

<!-- Increased Security:
Mitigate risks by receiving immediate alerts for unauthorized movements, ensuring the safety of your assets and personnel.<br>

Cost Savings:
Optimize routes, reduce fuel consumption, and minimize downtime, resulting in significant cost savings for your business.<br>

Customer Satisfaction:
Improve customer satisfaction by providing accurate delivery or service time estimates, enhancing the overall customer experience.<br> -->

<!-- Conclusion:
Elevate your business operations to new heights with our Live Location Tracking solution. Whether you're focused on logistics, field services, or any industry with a mobile workforce, our technology empowers you to make informed decisions, reduce costs, and stay ahead of the competition. Invest in the future of location intelligence – your business deserves nothing less. Contact us today to schedule a demo and see the transformative power of real-time location tracking in action.<br> -->
                           
                            </p>
                     
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- About Section End -->

   </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  mounted() {
    AOS.init({
      duration: 1100 // Set the default duration for all elements with data-aos-duration attribute
    });
  }
};
</script>