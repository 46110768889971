<template>
    <div>
       <!-- Breadcrumb Section Start -->
       <div v-if="loading == true" class="text-center">
					<LoadingBar />
				</div>
    <div class="section product-breadcrumb-section product-container">
        <div class="container-product">
            
            <div class="row">
                <div class="col-lg-6">

                    <!-- Breadcrumb wrapper Start -->
                    <div class="breadcrumb-wrapper1 mb-4">
                        <!-- <div  class="about-content mw-100"  style="font-weight:300"  data-aos="fade-up" data-aos-duration="1100" class="about-content mw-100"> -->
                        <h2 class="title" data-aos="fade-up" data-aos-duration="1100">Employee Management</h2>
                        <!-- <span></span> -->
                        <ul data-aos="fade-up" data-aos-duration="1100"
                            class="prod-main-con text-dark content-header-list about-content mw-100">
                            <li><strong>Core HR operations made simpler and better.</strong></li>
                            <li><strong>Add and update employee information.</strong></li>
                            <li><strong>Track employee details.</strong></li>
                            <li><strong>Send Reminders and alerts for birthdays/anniversaries.</strong></li>
                            <li><strong>Upload and view Company Policy documents.</strong></li>
                        </ul>
                        <!-- </div> -->
                        <!-- <ul class="breadcrumb-menu" data-aos="fade-up" data-aos-duration="1500">
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>
                                <span>Service</span>
                            </li>
                        </ul> -->
                    </div>
                    <!-- Breadcrumb wrapper End -->

                </div>
                <div class="col-lg-6">
                    <img src="assets\images\home\empMgmt.jpg" class="emp-img">
                </div>
                <!-- <div class="contact-banner-wrapper" data-bg-image="assets/images/products-images/employee.png"> -->
                <!-- <img src="assets/images/banner/contact-banner.jpg" alt="Contact Banner Image"> -->
                <!-- </div> -->
            </div>
        </div>

    </div>
    <!-- Breadcrumb Section End -->

      <!-- About Section Start -->
    <div class="section section-margin">
        <div class="container">
            <div class="row">
                <!-- <div class="col-md-5" >
            <img src="assets/images/products-images/leave application.png" width="500" height="400">
        </div> -->
                <div class="col-md-6">
                    <div class="about-content mw-100">
                        <h2 class="title">Employee Details</h2>
                        <span></span>
                        <p class="prod-sub-con">
                           Here we can conveniently add and organize employee details. By utilizing
                                    a structured layout, we can present essential information in a clear and concise
                                    manner. Each employee's profile can include relevant data such as their name, job
                                    title, department, contact information, and any additional details deemed necessary.
                                  </p>
                            <!-- <li><strong>Previous Employement Details</strong></li>
                <li></li><strong>Allocating Reporting Manager</strong></li> -->
                      
                    </div>
                </div>
                <div class="col-md-6">
                   <img src="" alt="">
                </div>

            </div>
        </div>
    </div>
    <!-- About Section End -->


    <!-- About Section Start -->
    <div class="section section-margin">
        <div class="container">
            <div class="row">
                <!-- <div class="col-md-5" >
            <img src="assets/images/products-images/Employee profile.png" width="500" height="400">
        </div> -->
                <div class="col-md-7">
                    <div class="about-content mw-100">
                        <h2 class="title">Employee Position Management</h2>
                        <span></span>
                        <p class="prod-sub-con">
                           In an organization, the ability to change employee positions, including
                                departments,divisions designations and branch, is a valuable practice that promotes
                                flexibility and growth. Such changes allow employees to explore new horizons, expand
                                their skill sets, and contribute to the organization in diverse ways.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- About Section End -->

    <!-- About Section Start -->
    <div class="section section-margin">
        <div class="container">
            <div class="row">
                <!-- <div class="col-md-5" >
            <img src="assets/images/products-images/Employee profile.png" width="500" height="400">
        </div> -->
                <div class="col-md-7">
                    <div class="about-content mw-100">
                        <h2 class="title">Assign Projects</h2>
                        <span></span>
                        <p class="prod-sub-con">
                            Assigning projects to employees in an organization is a pivotal process that plays a
                                significant role in achieving project success and harnessing the talents of the
                                workforce. It begins with a thorough evaluation of each employee's skills, expertise,
                                and interests, ensuring a proper match between the project requirements and the
                                employee's capabilities.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- About Section End -->

    <!-- About Section Start -->
    <div class="section section-margin">
        <div class="container">
            <div class="row">
                <!-- <div class="col-md-5" >
            <img src="assets/images/products-images/Employee profile.png" width="500" height="400">
        </div> -->
                <div class="col-md-7">
                    <div class="about-content mw-100">
                        <h2 class="title">Separation Process</h2>
                        <span></span>
                        <p class="prod-sub-con">
                           The separation process of an employee in an organization is a significant event that
                                requires careful handling and attention to ensure a smooth transition for both the
                                employee and the organization. It typically starts with a formal resignation or
                                termination notice. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- About Section End -->

    </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';
import LoadingBar from "../components/LoadingBar.vue";

export default {
    name: "EmployeeManagement",
    components: { LoadingBar },
  mounted() {
    AOS.init({
      duration: 1100 // Set the default duration for all elements with data-aos-duration attribute
    });
  }
};
</script>