<template>
    <div>
        <!-- Breadcrumb Section Start -->
    <div class="section product-breadcrumb-section product-container">
        <div class="container-product">
            <div class="row">
                <div class="col-lg-6">

                    <!-- Breadcrumb wrapper Start -->
                    <div class="breadcrumb-wrapper1 mb-4">
                        <h2 class="title " data-aos="fade-up" data-aos-duration="1100">Attendance Management</h2>
                        <!-- <span></span> -->
                        <ul class="prod-main-con text-dark content-header-list about-content mw-100"  data-aos="fade-up" data-aos-duration="1100"
                           >
                            <!-- <h2 class="title">Leave Management</h2> -->
                            <!-- <dl style="margin-left:2%"> -->
                            <li><strong>Shift Allocation: Allows Managers/HR to Allocate shifts to the teams. </strong></li>
                            <li><strong>Swipe details: Biometric Data Capture with Swipe in and Swipe out details.</strong></li>
                            <li><strong>Attendance Regularization: Regularize Attendance for missed swipes, late/early logouts.</strong></li>
                            <li><strong>Shift Schedule: Employees will be able to view the Shift Schedule.</strong></li>
                            <li><strong>Attendance Process - HR Executive responsible for attendance can process the attendance before the payroll process after which the payroll process takes place.</strong></li>
                        </ul>
                        <!-- </div> -->
                        <!-- <ul class="breadcrumb-menu" data-aos="fade-up" data-aos-duration="1500">
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>
                                <span>Service</span>
                            </li>
                        </ul> -->
                    </div>
                    <!-- Breadcrumb wrapper End -->

                </div>
                <div class="col-lg-6">
                    <img src="assets\images\home\attendance-mgmt-hs.jpg">
                        

                </div>
            </div>
        </div>
    </div>
    <!-- Breadcrumb Section End -->

    
    <!-- About Section Start -->
    <div class="section section-margin">
        <div class="container">
            <div class="row">
                <!-- <div class="col-md-5" >
        <img src="assets/images/products-images/leave application.png" width="500" height="400">
    </div> -->
                <div class="col-md-7">
                    <div class="about-content mw-100">
                        <h2 class="title">Swipe Details</h2>
                        <span></span>
                        <p class="prod-sub-con">
                          Swipe details By capturing the date and time of
                                    each swipe, these details provide an accurate record of employee presence and help
                                    establish accountability. Swipe details typically include the swipe location.</p>

                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- About Section End -->


    <!-- About Section Start -->
    <div class="section section-margin">
        <div class="container">
            <div class="row">
                <!-- <div class="col-md-5" >
        <img src="assets/images/products-images/leave application.png" width="500" height="400">
    </div> -->
                <div class="col-md-7">
                    <div class="about-content mw-100">
                        <h2 class="title">Attendance Details</h2>
                        <span></span>
                        <p class="prod-sub-con">
                           
                                Attendance Details provide a comprehensive record of employee attendance, including
                                    arrival and departure times, breaks, and absences. These details are typically captured through various methods,
                                    such as time clocks, biometric systems, swipe cards.

                                 
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- About Section End -->


    <!-- About Section Start -->
    <div class="section section-margin">
        <div class="container">
            <div class="row">
                <!-- <div class="col-md-5" >
        <img src="assets/images/products-images/leave application.png" width="500" height="400">
    </div> -->
                <div class="col-md-7">
                    <div class="about-content mw-100">
                        <h2 class="title">Attendance Process</h2>
                        <span></span>
                        <p class="prod-sub-con">
                             The recorded attendance information is then processed and calculated
                                    to determine the total working hours, including regular hours, overtime, and any
                                    adjustments for time-off or leave.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- About Section End -->

    <!-- About Section Start -->
    <div class="section section-margin">
        <div class="container">
            <div class="row">
                <!-- <div class="col-md-5" >
        <img src="assets/images/products-images/leave application.png" width="500" height="400">
    </div> -->
                <div class="col-md-7">
                    <div class="about-content mw-100">
                        <h2 class="title">Attendance Override</h2>
                        <span></span>
                        <p class="prod-sub-con">
                         Attendance override refers to the process of manually adjusting or overriding an
                                    employee's recorded attendance in exceptional circumstances. It is a mechanism
                                    employed when there is a need to modify an employee's attendance data due to
                                    specific situations.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- About Section End -->

    <div class="section section-margin">
        <div class="container">
            <div class="row">
                <!-- <div class="col-md-5" >
        <img src="assets/images/products-images/leave application.png" width="500" height="400">
    </div> -->
                <div class="col-md-7">
                    <div class="about-content mw-100">
                        <h2 class="title">Attendance Regularization</h2>
                        <span></span>
                        <p class="prod-sub-con">
                         Attendance regularization refers to the process of addressing and rectifying
                                    attendance discrepancies or irregularities within an organization.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>

    </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  mounted() {
    AOS.init({
      duration: 1100 // Set the default duration for all elements with data-aos-duration attribute
    });
  }
};
</script>