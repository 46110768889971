<template>
   <div>
      <!-- Breadcrumb Section Start -->
    <div class="mt-5">
     <img src="assets\images\home\onboarding-hs.jpg" alt="">
        <div class="container">
            <div class="row">
                <div class="col-12">

                    <!-- Breadcrumb wrapper Start -->
                    <div class="breadcrumb-wrapper">
                        <!-- <h2 class="title text-white" data-aos="fade-up" data-aos-duration="1100">Employee Onboarding</h2> -->

                        <!-- <ul class="breadcrumb-menu" data-aos="fade-up" data-aos-duration="1500">
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>
                                <span>Service</span>
                            </li>
                        </ul> -->
                    </div>
                    <!-- Breadcrumb wrapper End -->

                </div>
            </div>
        </div>
    </div>
    <!-- Breadcrumb Section End -->

    <!-- About Section Start -->
    <div class="section section-margin">
        <div class="container">
            <div class="row m-b-n30 align-items-center">
                <div class="col-lg-6 m-b-30" data-aos="fade-up" data-aos-duration="1100">
                    <div class="about-content mw-100">
                        <h2 class="title">Employee Onboarding</h2>
                        <p class="prod-sub-con">Employee onboarding is a crucial process that helps new hires transition smoothly into their roles within an organization. It involves providing the necessary information, resources, and support to help employees understand the company's culture, values, policies, and expectations.</p>
                        
                      
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <!-- About Section End -->
   </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  mounted() {
    AOS.init({
      duration: 1100 // Set the default duration for all elements with data-aos-duration attribute
    });
  }
};
</script>