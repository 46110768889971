<template>
  <div>
      <!-- Breadcrumb Section Start -->
    <div class="section product-breadcrumb-section product-container">
        <div class="container-product">
            <div class="row">
                <div class="col-lg-6">

                    <!-- Breadcrumb wrapper Start -->
                    <div class="breadcrumb-wrapper1 mb-4">
                        <h2 class="title" data-aos="fade-up" data-aos-duration="1100">Settings</h2>
                        <!-- <div class="text-dark" style="font-weight:300" data-aos="fade-up" data-aos-duration="1100" class="about-content mw-100"> -->
                        <span></span>
                        <ul class="text-dark content-header-list about-content mw-100" style="margin-left:2%" data-aos="fade-up" data-aos-duration="1100"
                           >
                            <li><strong> Settings enables to set the various
                                categories, able to set the leave, Holiday,
                                also enables to give access to the
                                employees on each module depend on the
                                designation & also displays machine
                                details & enable to create user ID for
                                employees, Able to import any data in
                                Excel format such as employee data,
                                Attendance data etc.</strong></li>
                           
                        </ul>
                        <!-- </div> -->
                        <!-- <ul class="breadcrumb-menu" data-aos="fade-up" data-aos-duration="1500">
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>
                                <span>Service</span>
                            </li>
                        </ul> -->
                    </div>
                    <!-- Breadcrumb wrapper End -->

                </div>
                <div class="col-lg-6">
                    <img src="assets\images\home\settings-img.png">
                </div>
            </div>
        </div>
    </div>
    <!-- Breadcrumb Section End -->

     <!-- About Section Start -->
    <div class="section section-margin">
        <div class="container">
            <div class="row">
                <!-- <div class="col-md-5" >
        <img src="assets/images/products-images/leave application.png" width="500" height="400">
        </div> -->
                <div class="col-md-7">
                    <div class="about-content mw-100">
                        <h2 class="title">Settings</h2>
                        <span></span>
                        <p style="margin-left:2%; text-align: justify;">
                            Settings enables to set the various
                                categories.


                               
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- About Section End -->


  </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  mounted() {
    AOS.init({
      duration: 1100 // Set the default duration for all elements with data-aos-duration attribute
    });
  }
};
</script>