<template>
 <div>
   <!-- Breadcrumb Section Start -->
    <div>
    <img src="https://www.allnetarticles.com/wp-content/uploads/2020/07/Top-10-Reasons-why-Companies-need-Certified-Project-Managers.jpg" alt="">
        <div class="container">
            <div class="row">
                <div class="col-12">

                    <!-- Breadcrumb wrapper Start -->
                    <div class="breadcrumb-wrapper">
                        <h1 class="title text-white" data-aos="fade-up" data-aos-duration="1100">Project Management
                        </h1>

                        <!-- <ul class="breadcrumb-menu" data-aos="fade-up" data-aos-duration="1500">
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>
                                <span>Service</span>
                            </li>
                        </ul> -->
                    </div>
                    <!-- Breadcrumb wrapper End -->

                </div>
            </div>
        </div>
    </div>
    <!-- Breadcrumb Section End -->

    <!-- About Section Start -->
    <div class="section section-margin">
        <div class="container">
            <div class="row m-b-n30 align-items-center">
                <div class="col-lg-6 m-b-30" data-aos="fade-up" data-aos-duration="1100">
                    <div class="about-content mw-100">
                        <h2 class="title">Project Management</h2>
                        <p class="text-dark" style="margin-left:1%; text-align: justify;"><strong>Project management is
                                the discipline of planning, organizing, and executing projects to achieve specific goals
                                and objectives within defined constraints. It involves the application of knowledge,
                                skills, tools, and techniques to effectively manage resources, timelines, budgets,
                                risks, and stakeholders. A project manager leads the project team, coordinates
                                activities, and ensures successful project completion. The project management process
                                typically includes initiating, planning, executing, monitoring, and closing phases.
                                During project initiation, goals, scope, and deliverables are defined, and a project
                                plan is developed. The planning phase involves creating a detailed project schedule,
                                identifying tasks, allocating resources, and assessing risks. In the execution phase,
                                the project plan is put into action, and tasks are performed according to the defined
                                schedule. The monitoring phase involves tracking progress, managing changes, and
                                ensuring that the project stays on track. Finally, the closing phase involves project
                                evaluation, documentation.</strong></p>
                        <span></span>

                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- About Section End -->

 </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  mounted() {
    AOS.init({
      duration: 1100 // Set the default duration for all elements with data-aos-duration attribute
    });
  }
};
</script>