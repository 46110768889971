<template>
<div>
  <!-- header section start -->
  <nav-bar />
  <!-- header section end -->

  <router-view></router-view>
 
  
  <!-- footer section start -->
  <app-footer />
  
  <!-- footer section end -->

  <div v-if="showUpArrow" class="up-arrow" @click="scrollToTop">
      <!-- Replace with your arrow icon or text -->
       ↑
    </div>
</div>
</template>

<script>
import NavBar from './components/NavBar.vue';
// import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  data() {
    return {
      showUpArrow: false,
    };
  },
  components: {
    NavBar,
    // AppHeader,
    AppFooter,
  },
  name: 'App',
   mounted() {
    // Add a scroll event listener to the window
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // Don't forget to remove the scroll event listener when the component is destroyed
    window.removeEventListener("scroll", this.handleScroll);
  },
   methods: {
    handleScroll() {
      // Check if the page is scrolled down, then show the arrow
      this.showUpArrow = window.scrollY > 0;
    },
    scrollToTop() {
      // Function to scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
/* Style your upward arrow button */
.up-arrow {
  position: fixed;
  bottom: 20px; /* Adjust the position as needed */
  right: 20px; /* Adjust the position as needed */
  width: 40px;
  height: 40px;
  background-color: #A295DF;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-size: 24px;
  cursor: pointer;
   /* Add smooth CSS transitions */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  opacity: 1;
  /* pointer-events: none; */
}
</style>
