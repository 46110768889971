<template>
<div>
      <!--== Start Footer Area Wrapper ==-->
    <footer class="section footer-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <!--== Start Footer Widget Area ==-->
                    <div class="footer-widget-area">
                        <div class="row m-b-n40">
                            <!-- <div class="col-sm-6 col-md-6 col-lg-3 m-b-40" data-aos="fade-up" data-aos-duration="1100">
                            <div class="widget-item pb-sm-20">
                                <h4 class="widget-title">WE ARE CREATIVE AGENCY</h4>
                                <div class="about-widget">
                                    <p>Nunc ut vehicula ante. Donec cursus felis diam, at vulputate ligula sollicitudin at. Vivamus massa lorem Lorem Ipsum is simple.</p>
                                    <div class="social-icons">
                                        <a href="#/"><i class="fa fa-facebook"></i></a>
                                        <a href="#/"><i class="fa fa-twitter"></i></a>
                                        <a href="#/"><i class="fa fa-pinterest-p"></i></a>
                                        <a href="#/"><i class="fa fa-dribbble"></i></a>
                                        <a href="#/"><i class="fa fa-behance"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                            <div class="col-sm-6 col-md-6 col-lg-3 d-flex justify-content-start justify-content-lg-start m-b-40"
                                data-aos="fade-up" data-aos-duration="1500">
                                <div class="widget-item">
                                    <h4 class="widget-title">PRODUCT</h4>
                                    <ul class="widget-list">
                                        <li><a href="employee-management">Employee Management</a></li>
                                        <li><a href="attendance-management">Attendance Management</a></li>
                                        <li><a href="leave-management">Leave management</a></li>
                                        <li><a href="payroll-management">Payroll Management</a></li>
                                        <li> <a href="/mobile-app">Mobile App</a></li>
             <li><a href="/live-location-tracking">Live Location Tracking</a></li> 
                                    </ul>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-3 m-b-40" data-aos="fade-up"
                                data-aos-duration="1100">
                                <div class="widget-item">
                                    <h4 class="widget-title">PRICING</h4>
                                    <ul class="widget-list">
                                        <li><a href="pricing">Pricing</a></li>
                                        
                                    </ul>
                                   
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-3 m-b-40" data-aos="fade-up"
                                data-aos-duration="2000">
                                <div class="widget-item">
                                    <h4 class="widget-title">COMPANY</h4>
                                    <ul class="widget-list">
                                        <li><a href="about">About Us</a></li>
                                        <li><a href="contact">Contact</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-3 m-b-40" data-aos="fade-up"
                                data-aos-duration="2000">
                                <div class="widget-item">
                                    <h4 class="widget-title">UPCOMING FEATURES</h4>
                                    <ul class="widget-list">
                                        <li><a href="employee-onboarding">Employee Onboarding </a></li>
                                        <li><a href="performance-appraisal">Performance Appraisal</a></li>
                                        <li><a href="asset-management">Asset Management</a></li>
                                        <li><a href="recruitment"> Recruitment</a></li>
                                    </ul>
                                </div>
                            </div>

                            <!-- <div class="col-sm-6 col-md-6 col-lg-3 m-b-40" data-aos="fade-up" -->
                            <!-- data-aos-duration="2000"> -->
                            <!-- <div class="widget-item"> -->
                                <!-- <h4 class="widget-title">SETTINGS</h4> -->
                                <!-- <ul class="widget-list">
                                    <li><a href="settings">Settings</a></li>
                                    
                                </ul> -->
                            <!-- </div> -->
                        <!-- </div> -->

                        <!-- <div class="col-sm-6 col-md-6 col-lg-3 m-b-40" data-aos="fade-up"
                        data-aos-duration="2000">
                        <div class="widget-item">
                            <h4 class="widget-title">PRICING</h4>
                            <ul class="widget-list">
                                <li><a href="pricing.html">Pricing</a></li>
                                
                            </ul>
                           
                        </div>
                    </div> -->
                            <!-- <div class="col-sm-6 col-md-6 col-lg-3 m-b-40" data-aos="fade-up" data-aos-duration="2500">
                            <div class="widget-item">
                                <h4 class="widget-title">REFOLLOW US INSTAGRAM</h4>
                                <ul class="widget-instagram">
                                    <li><a href="#/"><img class="fit-image" src="assets/images/instagram/2.jpg" alt="Image-HasTech"></a></li>
                                    <li><a href="#/"><img class="fit-image" src="assets/images/instagram/3.jpg" alt="Image-HasTech"></a></li>
                                    <li><a href="#/"><img class="fit-image" src="assets/images/instagram/1.jpg" alt="Image-HasTech"></a></li>
                                    <li><a href="#/"><img class="fit-image" src="assets/images/instagram/4.jpg" alt="Image-HasTech"></a></li>
                                </ul>
                            </div>
                        </div> -->
                        </div>
                    </div>
                    <!--== End Footer Widget Area ==-->
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="copyright-content">
                            <p class="mb-0"> 2022 ©<strong class="text-color-gray">Intellogicsoftware Pvt Ltd
                                </strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!--== End Footer Area Wrapper ==-->
</div>
   
</template>