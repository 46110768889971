<template>
   <div>
     <!-- Breadcrumb Section Start -->
    <div class="section product-breadcrumb-section product-container">
        <div class="container-product">
            <div class="row">
                <div class="col-lg-6">

                    <!-- Breadcrumb wrapper Start -->
                    <div class="breadcrumb-wrapper1 mb-4">
                        <h2 class="title" data-aos="fade-up" data-aos-duration="1100">Mobile App</h2>
                        <!-- <div class="text-dark" style="font-weight:300" data-aos="fade-up" data-aos-duration="1100" class="about-content mw-100"> -->
                        <span></span>
                        <ul class="prod-main-con text-dark content-header-list about-content mw-100" data-aos="fade-up" data-aos-duration="1100"
                           >
                            <li><strong> We are excited to bring the convenience and efficiency of Intellhrms Product to your fingertips with our feature-packed mobile application. Whether you're in the office, on the field, or simply on the move, our mobile app ensures that you stay connected and in control of your tasks and data anytime, anywhere.</strong></li>
                            <!-- <li><strong> Payroll Process, Payslip Generation, Lock Payroll, Arrears, LOP/LOP Reversal/Salary Advance.</strong></li>
                            <li><strong> Final Settlement for resigned employees</strong></li>
                            <li><strong>IT Declaration - Employee can declare the IT declaration in April and February once the account department enables it.</strong></li>
                            <li><strong> IT Declaration Approval - Accounts Head or Accounts Executive Responsible for the IT Declaration Will be approved after reviewing the details.</strong></li> -->
                        </ul>
                        <!-- </div> -->
                        <!-- <ul class="breadcrumb-menu" data-aos="fade-up" data-aos-duration="1500">
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>
                                <span>Service</span>
                            </li>
                        </ul> -->
                    </div>
                    <!-- Breadcrumb wrapper End -->

                </div>
                <div class="col-lg-6">
                    <img src="assets\images\representation-user-experience-interface-design.jpg">
                </div>
            </div>
        </div>
    </div>
    <!-- Breadcrumb Section End -->

      <!-- About Section Start -->
    <div class="section section-margin">
        <div class="container">
            <div class="row">
                <!-- <div class="col-md-5" >
        <img src="assets/images/products-images/leave application.png" width="500" height="400">
        </div> -->
                <div class="col-md-7">
                    <div class="about-content mw-100">
                        <h2 class="title">Mobile App</h2>
                        <span></span>
                        <p class="prod-sub-con">
                <!-- We are excited to bring the convenience and efficiency of Intellhrms Product to your fingertips with our feature-packed mobile application. Whether you're in the office, on the field, or simply on the move, our mobile app ensures that you stay connected and in control of your tasks and data anytime, anywhere.<br> -->

Key Features:<br>

Seamless Synchronization:
Enjoy a seamless transition between your desktop and mobile experience. Your data syncs effortlessly, allowing you to pick up where you left off, ensuring continuity in your workflow.<br>

Intuitive Mobile Interface:
Our mobile app is designed with a user-friendly interface tailored for smaller screens. Navigate effortlessly, access critical features with ease, and experience the same intuitive design you love from our web platform.<br>

On-the-Go Productivity:
Boost your productivity with quick access to essential tools and functionalities. Whether it's managing tasks, collaborating with team members, or reviewing critical data, our mobile app empowers you to stay productive no matter where you are.<br>

Offline Access:
Stay productive even without a stable internet connection. Our mobile app allows you to work offline, ensuring that you can access and update your information regardless of connectivity issues.<br>

Push Notifications:
Stay in the loop with real-time updates. Receive push notifications for important events, task reminders, or any critical updates, keeping you informed and proactive.<br>

Why Intellhrms Mobile App:<br>

Flexibility and Mobility:
Carry the power of our software solution wherever you go. Whether you're in the field, traveling, or away from your desk, the mobile app keeps you connected to your work and team.<br>

Enhanced User Experience:
Enjoy the same seamless experience you love from our web version, optimized for mobile usage. No compromises on functionality or usability – just a streamlined experience tailored for your device.<br>

Increased Accessibility:
Access your data and tools with just a few taps. Our mobile app ensures that you have quick and convenient access to your software, eliminating the need to wait until you're back at your desk.

Experience the freedom of managing your tasks, collaborating with your team, and accessing critical information effortlessly with the Intellhrms Mobile App.<br>
                            </p>
                     
                    </div>
                </div>
                <!-- <div class="col-md-5">
                     <img src="assets\images\login-page-snap.jpeg">
                </div> -->

            </div>
            <div class="row">
                <div class="col-md-3 p-3">
                     <img src="assets\images\login-page-snap.jpeg">
                </div>
              <div class="col-md-3 p-3">
  <img src="assets\images\leaves-snap.jpeg">
              </div>
               <div class="col-md-3 p-3">
  <img src="assets\images\dashboard-snap.jpeg">
              </div>
               <div class="col-md-3 p-3">
  <img src="assets\images\payslip-snap.jpeg">
              </div>
            </div>
        </div>
    </div>
    <!-- About Section End -->

   </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  mounted() {
    AOS.init({
      duration: 1100 // Set the default duration for all elements with data-aos-duration attribute
    });
  }
};
</script>