<template>
   <div>
       <!-- Login Content Start -->
    <div class="section product-breadcrumb-section product-container">
        <div class="container-product card">
           
            <div class="breadcrumb-wrapper">
                <h3 class="title">Login to IntellHRMS</h3>
                <form @submit.prevent="submitForm" class="css-txujy"><label for="email" class="css-tjq170  text-dark">Official Email:</label><input type="email" name="searchTerm" v-model="email" id="email" ref="userEmail" class="css-14lf73e" required>
                <p class="error-message" v-if="isInvalidEmail">Invalid email address.</p>
                <div class="css-uiem25"></div>
                <div class="css-1t6u4p9">
                    <div id="g-recaptcha" class="g-recaptcha" data-sitekey="6Ldl-KkUAAAAAEbiLOHHSKuQhQlB5pvWrhZOR4-E"
                        data-theme="light" data-type="image" data-size="normal" data-badge="bottomright"
                        data-tabindex="0">
                        <div style="width: 304px; height: 78px;">
                            <div><iframe title="reCAPTCHA"
                                    src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Ldl-KkUAAAAAEbiLOHHSKuQhQlB5pvWrhZOR4-E&amp;co=aHR0cHM6Ly93d3cuZ3JleXRoci5jb206NDQz&amp;hl=en&amp;type=image&amp;v=4rwLQsl5N_ccppoTAwwwMrEN&amp;theme=light&amp;size=normal&amp;badge=bottomright&amp;cb=z9tlrcrpape6"
                                    width="304" height="78" role="presentation" name="a-bcdm0y4d1ke8" frameborder="0"
                                    scrolling="no"
                                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"></iframe>
                            </div><textarea id="g-recaptcha-response" name="g-recaptcha-response"
                                class="g-recaptcha-response"
                                style="width: 250px; height: 40px; border: 1px solid rgb(193, 193, 193); margin: 10px 25px; padding: 0px; resize: none; display: none;"></textarea>
                        </div><iframe style="display: none;"></iframe>
                    </div>
                     <div v-if="isSending">
                     <p class="email-sending-mssg">Searching...</p>
                      <!-- Add your desired animations here -->
                      </div>
                 <div v-else-if="isSent">
                   <p class="email-sucess-mssg">{{this.message}}!</p>
                 </div>
                  <div v-else-if="sendingFailed">
                   <p class="email-error-mssg">Searching failed!</p>
                </div>
                    <div v-else
                 v-show="showDemoButtons" ><button class="btn btn-success mb-3">Search</button></div>
                </div>
                <div class="css-rh9dea"></div>
                </form>
            </div>
             
        </div>
    </div>
   </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios'; 

export default {
  data() {
    return {
      email: "",
      isSending: false,
      isSent: false,
      sendingFailed: false,
      showDemoButtons: true
      // token: process.env.INTELLHRMS_API_KEY,
    };
  },
  mounted() {
    console.log('hiiii');
    AOS.init({
      duration: 1100 // Set the default duration for all elements with data-aos-duration attribute
    });
  },
  computed: {
    isInvalidEmail() {
      return !this.isValidEmail(this.email) && this.email !== '';
    },
  },
  methods: {
    isValidEmail(email) {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

   submitForm (){
    const token = process.env.VUE_APP_INTELLHRMS_API_KEY;
     if (this.isValidEmail(this.email)){
   
    this.showDemoButtons = false;
     this.isSending = true;
     axios.get('https://demo.intellhrms.com/api/website-login-redirect', {
        params: {
          email: this.email,
        },
         headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
    })
      .then(response => {
         this.isSending = false;
        this.isSent = true;
        this.message = response.data.message;
        if(this.message == 'User found')
        {
          window.location.href = response.data.clientUrl;
        }
         setTimeout(() => {
        // Once the email is sent (simulated after 2 seconds), update the flags
        this.isSent = false;
        this.showDemoButtons = true;
        // Reset the form if needed
         this.clearForm()
      }, 1000);
       console.log('Email sent successfully',response);
      })
      .catch(error => {
        this.isSending = false;
        this.sendingFailed = true;
      
          setTimeout(() => {
        // Once the email is sent (simulated after 2 seconds), update the flags
        this.sendingFailed = false;
        this.showDemoButtons = true;
        // Reset the form if needed
         this.clearForm()
      }, 1000);
        console.error('Error sending email:', error);
      });
     }
   },


   clearForm(){
    this.email = "";
  }

  },
};
</script>
<style scoped>
 .error-message {
  color:#ff3333;
  font-weight: 430;
  font-size: 13px;
  text-align: start;
  margin-top: 5px;
  }
  .email-sucess-mssg {
    color: green;
    font-family: sans-serif;
  }
  .email-sending-mssg {
    color: blue;
    font-family: sans-serif;
  }
  .email-error-mssg {
    color: red;
    font-family: sans-serif;
  }
</style>