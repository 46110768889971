<template>
    <div>
       <!-- Breadcrumb Section Start -->
    <div class="mt-5">
        <img src="assets\images\home\asset-mn.jpg" alt="">
        <div class="container">
            <div class="row">
                <div class="col-12">

                    <!-- Breadcrumb wrapper Start -->
                    <div class="breadcrumb-wrapper">
                        <!-- <h2 class="title text-white" data-aos="fade-up" data-aos-duration="1100">Asset Management</h2> -->
                        
                        <!-- <ul class="breadcrumb-menu" data-aos="fade-up" data-aos-duration="1500">
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>
                                <span>Service</span>
                            </li>
                        </ul> -->
                    </div>
                    <!-- Breadcrumb wrapper End -->

                </div>
            </div>
        </div>
    </div>
    <!-- Breadcrumb Section End -->

    <!-- About Section Start -->
    <div class="section section-margin">
        <div class="container">
            <div class="row m-b-n30 align-items-center">
                <div class="col-lg-6 m-b-30" data-aos="fade-up" data-aos-duration="1100">
                    <div class="about-content mw-100">
                        <h2 class="title">Asset Management</h2>
                        <!-- <span></span> -->
                        <p class="prod-sub-con">Asset management refers to the systematic and strategic approach of tracking, maintaining, and maximizing the value of an organization's assets. It involves effectively managing both tangible and intangible assets throughout their lifecycle.</p>
                      
                    </div>
                </div>
                
            </div>
        </div>
    </div>
   
   </div>
    
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  mounted() {
    AOS.init({
      duration: 1100 // Set the default duration for all elements with data-aos-duration attribute
    });
  }
};
</script>