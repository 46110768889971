<template>
   <div>
     <!-- Breadcrumb Section Start -->
    <div>
        <!-- <img src="https://cdn.dribbble.com/users/1220038/screenshots/12513236/media/cc99a02f20ee78a29ac8679ffbb50bc9.jpg" alt=""> -->
         <div class="image-container">
    <img src="assets\images\home\pricing-hs.jpg" alt="Image" class="image" />
    <button  class="btn image-button"><a href="#detail_table">Choose Now</a></button>
  </div>
        <div class="container">
            <div class="row">
                <div class="col-12">

                    <!-- Breadcrumb wrapper Start -->
                    <!-- <div class=""> -->
                     
                        <!-- <h2 class="title text-white" data-aos="fade-up" data-aos-duration="1100">Pick Your Plan</h2> -->
                        <!-- <button class="choose-button"><a href="#detail_table">Choose Now</a></button> -->
                        <!-- <h1>Choose Your Plan</h1> -->
                        <!-- <ul class="breadcrumb-menu" data-aos="fade-up" data-aos-duration="1500">
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>
                                <span>Service</span>
                            </li>
                        </ul> -->
                    <!-- </div> -->
                    <!-- Breadcrumb wrapper End -->

                </div>
            </div>
        </div>
    </div>
    <!-- Breadcrumb Section End -->

    <!-- About Section Start -->
    <div class="pricing-body">
        <div class="container">
            <div class="row m-b-n70 ">
                <div class="col-lg-12 m-b-30">
                    <div class="table mt-5">
                     <table id="detail_table" class="pricing-table table-responsive table-bordered fix-table-head mx-auto" >
                            <thead>
       
                                <tr>
                                    <th class="category-column text-nowrap" >CATEGORY </th>
                                     <th class="basic-column text-nowrap">BASIC PLAN<br> (2 months free) </th>
                                    <th class="standard-column text-nowrap">STANDARD PLAN </th>
                                    <th class="super-column text-nowrap">SUPER PLAN </th>
                                    <th class="premium-column text-nowrap">PREMIUM PLAN</th>
                                </tr>
                            </thead>
                            <tbody v-for="(feature,index) in features" :key="feature.id">
                              <tr class="" v-if="index==0">
                                 <td class="category-sub">Online Usage charges</td>
                                 <td class="basic-sub text-nowrap"><span class="basic-sub-head">₹1375/month</span><br>
                                                    (up to 15
                                                 Employees) <br>
                                       </td>
                                         <td class="standard-sub text-nowrap"><span >₹3500/month</span> <br>
                                        (upto 25 Employees)</td>
                                          <td class="super-sub text-nowrap"><span >₹7000/month</span><br>
                                        (upto 50 Employees)</td>
                                          <td class="premium-sub text-nowrap"><span >Per Employee Charge</span><br>
                                           ₹1500/- PER ANNUM<br>
                                                            (100 & Above
                                               Employees) <br>
                                        </td>
                              </tr>
                                 <!-- <tr class="pricetag" v-if="index==0">
                        
                                    <td class="">
                                        Minimum Monthly Cost</td>
                                    <td class="text-nowrap"  style="text-align:center">₹3500/month <br>
                                        (upto 50 Employees)</td>
                                    <td class="text-nowrap" style="text-align:center">₹9500/month <br>
                                        (100 Employees)</td>
                                    <td class="text-nowrap" style="text-align:center">Per Employee Charge ₹1500/- PER ANNUM <br>
                                        (100 & Above Employees)</td>
                                </tr> -->
                                <tr  v-if="index==0">
                                    <td class="category-sub">
                                        One Time Implementation Charges</td>
                                        <td class="basic-sub">1000/-
                                    </td>
                                    <td class="standard-sub">₹2500/-
                                    </td>
                                    <td class="super-sub">₹3500/-
                                    </td>
                                    <td class="premium-sub">5000/-</td>
                                </tr>
                                <tr  v-if="index==0">
                                    <td class="category-sub">
                                        Number of Employees</td>
                                         <td class="basic-sub">Limited</td>
                                    <td class="standard-sub">Limited
                                    </td>
                                    <td class="super-sub">Limited
                                    </td>
                                    <td class="premium-sub">Unlimited</td>
                                </tr>
                                <tr  v-if="index==0">
                                    <td class="category-sub text-nowrap">
                                        Cost Per Additional Employee</td>
                                         <td class="basic-sub">25/- Per month <br>(Employee
                                          Count Should
                                            not exceed
                                           above 15)</td>
                                    <td class="standard-sub" >40/- Per month <br> (Employee
                                          Count Should
                                            not exceed
                                           above 25)</td>
                                    <td class="super-sub">70/- Per month <br> (to Continue With
                                     the Same Plan for upto 100
                                    Employees)</td>
                                    <td class="premium-sub"><span>₹120/- Per Month <br> or <br> 1,440/- Per ANNUM </span>
                                    </td>
                                </tr>
                                 <tr  v-if="index==0">
                                    <td class="category-sub">Dashboard</td>
                                     <td class="basic-sub">✔</td>
                                    <td class="standard-sub">✔</td>
                                    <td class="super-sub">✔</td>
                                    <td class="premium-sub">✔</td>
                                </tr>
                                  
                                <tr class="parent" >
                                    <td class="category-sub">
                                    <i class="category-sub-icon" v-if="feature.children.length > 0" :id="'icon-' + feature.id" :class="{'fa fa-plus': !feature.expanded, 'fa fa-minus': feature.expanded}"  @click="toggleChildRows(feature.id)"></i>
                                     &nbsp;{{ feature.name }}
                                   </td>
                                   <td class="basic-sub">{{ feature.availability }}</td>
                                   <td class="standard-sub">{{ feature.capability1 }}</td>
                                   <td class="super-sub">{{ feature.capability2 }}</td>
                                   <td class="premium-sub">{{ feature.capability3 }}</td>
                                   
                                </tr>
                                <tr class="child-row" v-for="childRow in filterChildRow(feature.id)" :key="childRow.id" v-show="childRow.visible">
                                    <td class="category-sub-child">{{ childRow.name }}</td>
                                    <td class="basic-sub-child">{{ childRow.capability1 }}</td>
                                    <td class="standard-sub-child">{{ childRow.capability2 }}</td>
                                    <td class="super-sub-child">{{ childRow.capability3 }}</td>
                                    <td class="premium-sub-child">{{ childRow.capability4 }}</td>
                                </tr>
                                  <tr  v-if="index==6">
                                    <td class="custom-plan-sub" colspan="5"><span class="custom-plan-head"> CUSTOM PLAN</span><br>
                                    <li class="custom-plan-cont">All the features available in PREMIUM PLAN will be provided in CUSTOM PLAN</li>
                                    <li class="custom-plan-cont">Additional features or customization option is available as per client’s request.</li>
                                     <li class="custom-plan-cont">Customization is charged @750/- Per Hour if Change Request is Given after 1 Month of Signing the Agreement</li>
                                    <li class="custom-plan-cont">Employee count should not be less than 200 to avail CUSTOM PLAN</li>
                                    <li class="custom-plan-cont">If the employee count exceeds the permitted limit, charges will be applicable as per Premium Plan</li>
                                    <li class="custom-plan-cont">Implementation on dedicated Cloud Server is also available(Only on Client request)</li>
                                   
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="custom-plan-gst" colspan="5"  v-if="index==6">All latest updates of IntellHRMS will be provided as and when new feature is added/updated.<br>
                                    <span class="email-error-mssg">*</span>&nbsp; GST @ 18% applicable for all plans</td>
                                  </tr>
                              </tbody>
                          </table>
                    </div>

                </div>

            </div>
        </div>
    </div>
    <!-- About Section End -->
   </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
    data() {
     return {
      features: [
        {
          id: 'row1',
          name: 'Employee Management',
          availability: 'Limited',
          capability1: 'Limited',
          capability2: '✔',
          capability3: '✔',
          expanded: false,
          children: [
            { id: 'child-row1', name: 'Add employee basic details', capability1: '✔', capability2: '✔', capability3: '✔', capability4: '✔', visible: false },
              { id: 'child-row2', name: 'PF/ESI details, Bank Details, Nominee', capability1: '✔', capability2: '✔', capability3: '✔',capability4: '✔', visible: false },
               { id: 'child-row3', name: 'Upload employee documents', capability1: '✔', capability2: '✔', capability3: '✔',capability4: '✔', visible: false },
                 { id: 'child-row4', name: 'Transfer employee from one division to another, etc.', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                  { id: 'child-row5', name: 'Apply Resignation', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                  { id: 'child-row6', name: 'Separation Process', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                  { id: 'child-row7', name: 'Apply Resignation', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                  { id: 'child-row8', name: 'Separation Process', capability1: '✔', capability2: '✔', capability3: '✔',capability4: '✔', visible: false },
                { id: 'child-row9', name: 'Exit Interview', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                 { id: 'child-row10', name: 'Exit Formalities', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
              { id: 'child-row11', name: 'Appointment Letter', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
               { id: 'child-row12', name: 'Confirmation letter', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
               { id: 'child-row13', name: 'Experience letter', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                { id: 'child-row14', name: 'Relieving letter', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                { id: 'child-row15', name: 'Self-appraisal form', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                 { id: 'child-row16', name: 'Appraisal review', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
         // Add other child rows here
          
          ]
        },
         {
          id: 'row2',
          name: 'Attendance/Leave',
          availability: 'Limited',
          capability1: 'Limited',
          capability2: '✔',
           capability3: '✔',
          expanded: false,
          children: [
            { id: 'child-row1', name: 'Attendance details', capability1: '✔', capability2: '✔', capability3: '✔',capability4: '✔', visible: false },
              { id: 'child-row2', name: 'Attendance process', capability1: '✔', capability2: '✔', capability3: '✔',capability4: '✔', visible: false },
               { id: 'child-row3', name: 'Employee swipes', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                { id: 'child-row4', name: 'Attendance regularization', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                { id: 'child-row5', name: 'Shift Allocation option for the Managers to allocate shift to the team members', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                { id: 'child-row6', name: 'Shift Roster', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                  { id: 'child-row7', name: 'Leave Grant', capability1: 'X', capability2: '✔', capability3: '✔',capability4: '✔', visible: false },
                   { id: 'child-row8', name: 'Leave Application', capability1: 'X', capability2: '✔', capability3: '✔',capability4: '✔', visible: false },
                   { id: 'child-row9', name: 'Attendance override', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                  { id: 'child-row10', name: 'Holiday Setting', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                 { id: 'child-row11', name: 'Leave Pay Report', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                  { id: 'child-row12', name: 'Shop wise Attendance Report', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                { id: 'child-row13', name: 'Leave Grant Approval', capability1: 'X', capability2: '✔', capability3: '✔',capability4: '✔', visible: false },
              { id: 'child-row14', name: 'Leave Approval', capability1: 'X', capability2: '✔', capability3: '✔',capability4: '✔', visible: false },
                { id: 'child-row15', name: 'Attendance Regularization Approval', capability1: 'X', capability2: '✔', capability3: '✔',capability4: '✔', visible: false },
          // Add other child rows here
          ]
        },
         {
          id: 'row3',
          name: 'Payroll Management',
          availability: 'Limited',
          capability1: 'Limited',
          capability2: '✔',
          capability3: '✔',
          expanded: false,
          children: [
            { id: 'child-row1', name: 'Payroll Dashboard', capability1: '✔', capability2: '✔', capability3: '✔', capability4: '✔', visible: false },
              { id: 'child-row2', name: 'Salary Revision', capability1: '✔ (Limited)', capability2: '✔ (Limited)', capability3: '✔',capability4: '✔', visible: false },
               { id: 'child-row3', name: 'Bulk Salary Revision', capability1: 'X', capability2: 'X', capability3: '✔', capability4: '✔',visible: false },
                { id: 'child-row4', name: 'Salary Revision History', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
               { id: 'child-row5', name: 'Payroll Inputs – Salary', capability1: '✔ (Limited)', capability2: '✔ (Limited)', capability3: '✔',capability4: '✔', visible: false },
                { id: 'child-row6', name: 'Arrear', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                 { id: 'child-row7', name: 'Loan Application', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                  { id: 'child-row8', name: 'Hold/Stop Salary', capability1: '✔', capability2: '✔', capability3: '✔',capability4: '✔', visible: false },
                   { id: 'child-row9', name: 'Final Settlement', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                    { id: 'child-row10', name: 'Final Settlement Pay Slip', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                    { id: 'child-row11', name: 'Salary Advance', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                     { id: 'child-row12', name: 'Loan', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                     { id: 'child-row13', name: 'Payroll Process', capability1: '✔', capability2: '✔', capability3: '✔',capability4: '✔', visible: false },
                      { id: 'child-row14', name: 'Quick Salary Statement', capability1: '✔', capability2: '✔', capability3: '✔',capability4: '✔', visible: false },
                       { id: 'child-row15', name: 'Payslips', capability1: '✔', capability2: '✔', capability3: '✔',capability4: '✔', visible: false },
                     { id: 'child-row16', name: 'Income Tax Declaration', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                      { id: 'child-row17', name: 'Year to date Summary', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                       { id: 'child-row18', name: 'Salary Register', capability1: '✔', capability2: '✔', capability3: '✔',capability4: '✔', visible: false },
                       { id: 'child-row19', name: 'CTC Report', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                          { id: 'child-row20', name: 'Arrear Report', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                        { id: 'child-row21', name: 'PF Report', capability1: '✔', capability2: '✔', capability3: '✔',capability4: '✔', visible: false },
                         { id: 'child-row22', name: 'ESI Report', capability1: '✔', capability2: '✔', capability3: '✔',capability4: '✔', visible: false },
                         { id: 'child-row23', name: 'IT Statement', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                          { id: 'child-row24', name: 'Cash/Cheque Statement', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                         { id: 'child-row25', name: 'Bank Transfer Format', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                         { id: 'child-row26', name: 'IT Declaration Approval', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
       // Add other child rows here
          ]
        },
         {
          id: 'row4',
          name: 'Settings',
          availability: 'Limited',
          capability1: 'Limited',
          capability2: 'Limited',
          capability3: '✔',
          expanded: false,
          children: [
            { id: 'child-row1', name: 'Import Data', capability1: '✔', capability2: '✔', capability3: '✔',capability4: '✔', visible: false },
              { id: 'child-row2', name: 'Multi Company Details', capability1: 'X', capability2: 'X', capability3: 'X',capability4: '✔', visible: false },
              { id: 'child-row3', name: 'Shift setting', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
              { id: 'child-row4', name: 'User Management', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
              { id: 'child-row5', name: 'Link User Roles', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
              { id: 'child-row6', name: 'Role Master', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
              { id: 'child-row7', name: 'Role Permission Map', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
              { id: 'child-row8', name: 'Ticket Support', capability1: '✔', capability2: '✔', capability3: '✔',capability4: '✔', visible: false },
            // Add other child rows here
          ]
        },
          {
          id: 'row5',
          name: 'Mobile App',
          availability: 'X',
          capability1: 'X',
          capability2: '✔',
           capability3: '✔',
          expanded: false,
          children: [
            // Add other child rows here
          ]
        },
         {
          id: 'row6',
          name: 'Statutory Compliance',
          availability: 'X',
          capability1: 'X',
          capability2: '✔',
          capability3: '✔',
          expanded: false,
          children: [
            { id: 'child-row1', name: 'Updated central & state wise compliance reports to make your cyclic filings easy', capability1: 'X', capability2: 'X', capability3: '✔', capability4: '✔', visible: false },
            // Add other child rows here
          ]
        },
         {
          id: 'row7',
          name: 'Upcoming Features',
          availability: 'X',
          capability1: 'X',
          capability2: '✔',
          capability3: '✔',
          expanded: false,
          children: [
            { id: 'child-row1', name: 'Travel Expense', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
              { id: 'child-row2', name: 'Recruitment & Onboarding', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                { id: 'child-row3', name: 'Project Management & Tracking', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
                 { id: 'child-row3', name: 'Asset Management', capability1: 'X', capability2: 'X', capability3: '✔',capability4: '✔', visible: false },
            // Add other child rows here
          ]
        },
         
        // Add other parent features here
      ]
    };
  },
  
 
  mounted() {
    AOS.init({
      duration: 1100 // Set the default duration for all elements with data-aos-duration attribute
    });
  },

  methods: {
    toggleChildRows(featureId) {
      const feature = this.features.find(feature => feature.id === featureId);
      feature.expanded = !feature.expanded;
      feature.children.forEach(childRow => {
        childRow.visible = !childRow.visible;
      });
    },
    filterChildRow(id){
       
      return this.features.filter(i=>i.id == id)[0]?.children
    }
  },
  computed: {
    // getChildRows() {
    //   return this.features.flatMap(feature => feature.children);
    // }
  }
};

</script>

<style scoped>
.category-column {
  text-align:center; width:20%;color:#666666 ; font-size:16px;padding:25px;background-color:#f2f2f2;
}
.custom-plan-head {
  font-size:16px;font-weight: bold;color: #ffff;
}
.custom-plan-gst {
  background-color: #4728a4;font-size:13px;font-weight: bold;color: #ffff;
}
.custom-plan-sub {
  font-size:13px;background-color:rgb(242 242 242 / 53%); color: #ffff;background-color: #4728a4;
}
.basic-column {
  text-align:center; line-height: 1.3;color:#333333; font-size:16px;background-color: #DFF0D8;
}
.standard-column {
 text-align:center;  color: #333333  ; font-size:16px;padding:25px;background-color:#DCE6F9;
}
.super-column {
 text-align:center;  color: #333333 ; font-size:16px;padding:25px;background-color:rgb(255 224 178 / 70%);
}
.premium-column {
 text-align:center;   font-weight: 1000px; color: #333333 ;padding:25px; font-size:16px;background-color:#fff099;
}
.pricing-table {
  overflow-x: auto !important; font-size:12px; width:85%; height:1190px; border: 1px solid #ddd;border-collapse: collapse;
}
.category-sub {
  font-size:13px;background-color:rgb(242 242 242 / 53%); font-weight:bold;
}
.standard-sub {
  text-align:center;background-color:#eaf0fb;font-weight:bold;
}
.super-sub {
 text-align:center;background-color:rgb(253 234 195 / 58%);font-weight:bold;
}
.premium-sub {
  text-align:center;background-color:rgb(255 237 166 / 78%);font-weight:bold;
}
   .hidden {
     display: none;
    }
  .image-container {
    position: relative;
    display: inline-block;
  }
  
  .image-button {
    /* color: white; */
    position: absolute;
    color: white ; background-color: pink ;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
.basic-plan-color-head {
  color: #4d4d4d;
  font-size: 14px;
}
  .image-button :hover {
    color: #d20c0c;
  }
  .image {
    display: block;
    max-width: 100%;
  }
  .email-error-mssg {
    color: red;
    font-size: 20px;
  }
  .pricing-body {
    background-color: rgb(255 230 255 / 10%);
  }
  .basic-sub {
    text-align:center;background-color:#f0f8ed; font-weight:bold; font-size:13px;
  }
  .basic-sub-head {
    font-size:13px;
  }
  .category-sub-child {
    padding-left: 32px;background-color:rgb(242 242 242 / 53%);
  }
  .basic-sub-child {
    text-align:center;background-color: #f0f8ed;
  }
  .standard-sub-child {
    text-align:center;background-color:#eaf0fb;
  }
  .super-sub-child {
     text-align:center;background-color:rgb(253 234 195 / 58%);
  }
  .premium-sub-child {
    text-align:center;background-color:rgb(255 237 166 / 78%);
  }
  .custom-plan-cont {
    padding-left: 20px;
  }
  .category-sub-icon {
    cursor: pointer;
  }
</style>
