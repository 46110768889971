<template>
   <div>
     <!-- Breadcrumb Section Start -->
    <div class="section product-breadcrumb-section product-container">
        <div class="container-product">
            <div class="row">
                <div class="col-lg-6">

                    <!-- Breadcrumb wrapper Start -->
                    <div class="breadcrumb-wrapper1 mb-4">
                        <h2 class="title" data-aos="fade-up" data-aos-duration="1100">Payroll Management</h2>
                        <!-- <div class="text-dark" style="font-weight:300" data-aos="fade-up" data-aos-duration="1100" class="about-content mw-100"> -->
                        <span></span>
                        <ul class="prod-main-con text-dark content-header-list about-content mw-100" data-aos="fade-up" data-aos-duration="1100"
                           >
                            <li><strong> Add Salary, Salary Revision, and Salary Increment.</strong></li>
                            <li><strong> Payroll Process, Payslip Generation, Lock Payroll, Arrears, LOP/LOP Reversal/Salary Advance.</strong></li>
                            <li><strong> Final Settlement for resigned employees</strong></li>
                            <li><strong>IT Declaration - Employee can declare the IT declaration in April and February once the account department enables it.</strong></li>
                            <li><strong> IT Declaration Approval - Accounts Head or Accounts Executive Responsible for the IT Declaration Will be approved after reviewing the details.</strong></li>
                        </ul>
                        <!-- </div> -->
                        <!-- <ul class="breadcrumb-menu" data-aos="fade-up" data-aos-duration="1500">
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>
                                <span>Service</span>
                            </li>
                        </ul> -->
                    </div>
                    <!-- Breadcrumb wrapper End -->

                </div>
                <div class="col-lg-6">
                    <img src="assets\images\home\payroll-hs.jpg">
                </div>
            </div>
        </div>
    </div>
    <!-- Breadcrumb Section End -->

      <!-- About Section Start -->
    <div class="section section-margin">
        <div class="container">
            <div class="row">
                <!-- <div class="col-md-5" >
        <img src="assets/images/products-images/leave application.png" width="500" height="400">
        </div> -->
                <div class="col-md-7">
                    <div class="about-content mw-100">
                        <h2 class="title">Payroll Process</h2>
                        <span></span>
                        <p class="prod-sub-con">
                            The payroll process is a critical function within an organization that involves
                                calculating and disbursing employee compensation, including salaries, wages,
                                bonuses, and deductions. Enables to Process the Payroll Which Compute All the Salary Components, Reimbursements, Arrears, Settlement, Statutory Components Including Income Tax.
                           
                            </p>
                     
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- About Section End -->

   </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  mounted() {
    AOS.init({
      duration: 1100 // Set the default duration for all elements with data-aos-duration attribute
    });
  }
};
</script>