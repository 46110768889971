<template>
    <div>
       <!-- Breadcrumb Section Start -->
    <div class="section product-breadcrumb-section product-container">
        <div class="container-product">
            <div class="row">
                <div class="col-lg-6">

                    <!-- Breadcrumb wrapper Start -->
                    <div class="breadcrumb-wrapper1 mb-4">

                        <h2 class="title " data-aos="fade-up" data-aos-duration="1100">Leave Management</h2>
                        <!-- <div class="text-dark" style="font-weight:300" data-aos="fade-up" data-aos-duration="1100" class="about-content mw-100"> -->
                        <!-- <h2 class="title">Leave Management</h2> -->
                        <ul class="prod-main-con text-dark content-header-list about-content mw-100" data-aos="fade-up" data-aos-duration="1100"
                            >
                            <li><strong>Leave Application: Apply for Leaves (SL/CL/EL/LOP/Restricted Holiday, COMP-OFF) online. </strong></li>
                            <li><strong>Leave Grant/OT Grant: if an employee has worked on an off day or a holiday, he/she will be able to apply for Comp-off which can be utilized in the future days by the employee, OT can be granted to an employee if he/she has worked past the working hours.</strong></li>
                            <li><strong>Leave Approval - Approval option for Reporting Managers.</strong></li>
                        </ul>
                        <!-- </div>   -->
                        <!-- <ul class="breadcrumb-menu" data-aos="fade-up" data-aos-duration="1500">
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>
                                <span>Service</span>
                            </li>
                        </ul> -->
                    </div>
                    <!-- Breadcrumb wrapper End -->

                </div>
                <div class="col-lg-6">
                    <img src="assets\images\home\leave-mgmt-hs.jpg"
                        width="500" height="400">
                </div>
            </div>
        </div>
    </div>
    <!-- Breadcrumb Section End -->

     <div class="section section-margin">
        <div class="container">
            <div class="row">
                <!-- <div class="col-md-5" >
            <img src="assets/images/products-images/leave application.png" width="500" height="400">
        </div> -->
                <div class="col-md-7">
                    <div class="about-content mw-100">
                        <h2 class="title">Leave Application</h2>
                        <span></span>
                        <p class="prod-sub-con">
                         
                                    A leave application is a formal request made by an employee to their supervisor or
                                    the designated authority within an organization, seeking approval for time off from
                                    work. It is an essential process that allows employees to take planned leaves for
                                    various reasons, such as personal matters, vacation, illness, or family commitments.
                                   
                        </p>
                     
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section section-margin">
        <div class="container">
            <div class="row">

                <div class="col-md-7">
                    <div class="about-content mw-100">
                        <h2 class="title">Leave Grant/OT Grant</h2>
                        <span></span>
                        <p class="prod-sub-con">
                          If an Employee has worked on an off day or a holiday, he/she will be able to
                                    apply for Comp-off which can be utilized in the future days by the employee.
                        </p>
                           
                    </div>
                </div>

                <!-- <div class="col-md-5" >
            <img src="assets/images/products-images/leave application.png" width="500" height="400">
        </div> -->
            </div>
        </div>
    </div>

    <div class="section section-margin">
        <div class="container">
            <div class="row">
                <!-- <div class="col-md-5" >
            <img src="assets/images/products-images/leave application.png" width="500" height="400">
        </div> -->
                <div class="col-md-7">
                    <div class="about-content mw-100">
                        <h2 class="title">Leave Approval</h2>
                        <span></span>
                        <p class="prod-sub-con">
                           Approval option for Reporting managers/Admin to approve various types of leaves. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  mounted() {
    AOS.init({
      duration: 1100 // Set the default duration for all elements with data-aos-duration attribute
    });
  }
};
</script>