<template>
<div>
   <!-- Breadcrumb Section Start -->
    <div>
        <img src="assets/images/slider/about-us.jpg" alt="">
        <div class="container">
            <div class="row">
                <div class="col-12">

                    <!-- Breadcrumb wrapper Start -->
                    <div class="prod-main-con breadcrumb-wrapper">
                        <!-- <h2 class="title text-white" data-aos="fade-up" data-aos-duration="1100">About Us</h2> -->
                        <!-- <h1 class="title text-white" data-aos="fade-up" data-aos-duration="1100">We Manage Your Resource Better</h1> -->

                        <!-- <ul class="breadcrumb-menu" data-aos="fade-up" data-aos-duration="1500">
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>
                                <span>About Us</span>
                            </li>
                        </ul> -->
                    </div>
                    <!-- Breadcrumb wrapper End -->

                </div>
            </div>
        </div>
    </div>
    <!-- Breadcrumb Section End -->

    <!-- About Section Start -->
    <div class="section section-margin">
        <div class="container">
            <div class="row m-b-n30">
                <div class="col-lg-6 align-self-center m-b-30" data-aos="fade-up" data-aos-duration="1100">
                    <div class="about-margin">
                        <h2 class="title m-b-40">We Provide Innovative Solutions to Grow Your Business.</h2>
                        <p class="about-cont">A Software Company founded
                                on Trust and Professional Work Practices.</p>
                        <p class="about-cont">Intellogic Software is a
                                Start-up founded with mind-set to deliver the best.</p>
                        <p class="about-cont">We not only grow but also
                                give chance to fresher's to join us in our magnificient journey.</p>
                        <p class="about-cont">For us, Customer
                                Satisfaction and Employee friendly environment is always of top priority.</p>
                        <!-- <p>Voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur mag os qui ratione voluptatem sequi nesciunt. Neque porro quisq uam est, qui dolorem ipsum quia dolor sit amet, consectetur some of the main thing is that we can solve your Business Problems.</p> -->
                        <!-- <a href="about.html" class="btn btn-dark btn-hover-primary m-t-30">Explore Now</a> -->
                    </div>
                </div>
                <!-- <div class="col-lg-6 order-first m-b-30" data-aos="fade-up" data-aos-duration="1500">
                    <div class="about-image me-auto">
                        <img src="assets/images/about/about-1.png" alt="About Image">
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <!-- About Section End -->
</div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  mounted() {
    AOS.init({
      duration: 1100 // Set the default duration for all elements with data-aos-duration attribute
    });
  }
};
</script>