<template>




       <!-- Header Section Start -->
    <div class="header">
        <div class="product-container-header text-dark">
           
     <nav class="navbar navbar-expand-xl navbar-light bg-light">
 
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-space nav-item active" >
        <a class="nav-link" href="/">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-space nav-item" >
        <a class="nav-link" href="/about">About Us</a>
      </li>
      <li class="nav-space nav-item dropdown" >
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Products
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="/employee-management">Employee Management</a>
          <a class="dropdown-item" href="/attendance-management">Attendance Management</a>
         
          <a class="dropdown-item" href="/leave-management">Leave Management</a>
          <a class="dropdown-item" href="/payroll-management">Payroll Management</a>
            <a class="dropdown-item" href="/mobile-app">Mobile App</a>
              <a class="dropdown-item" href="/live-location-tracking">Live Location Tracking</a>
        </div>
      </li>
      <li class="nav-space nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Upcoming Features
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="/employee-onboarding">Employee Onboarding</a>
          <a class="dropdown-item" href="/performance-appraisal">Performance Appraisal</a>
        
          <a class="dropdown-item" href="/asset-management">Asset Management</a>
          <a class="dropdown-item" href="/recruitment">Recruitment</a>
        </div>
      </li>
      <li class="nav-space nav-item" >
        <a class="nav-link" href="/pricing">Pricing</a>
      </li>
      <li class="nav-space nav-item">
        <a class="nav-link" href="/contact">Contact</a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" href="/settings">Settings</a>
      </li> -->
    </ul>
    <div></div>
    <ul class="navbar-nav mr-auto">
       
       <li class="float-right col-xs-2 col-sm-4 col-md-5 col-lg-3 col-xl-6 m-2" >
                                    <!-- <button id="header_login_btn"> -->
                                    <a class="dropdown-item text-center btn btn-primary btn-pill" href="https://demo.intellhrms.com">
                                       Get a Free Trial </a>
                                    <!-- </button> -->
      </li>
       <li class="float-right col-xs-2 col-sm-4 col-md-5 col-lg-3 col-xl-6 m-2" >
                                    <!-- <button id="header_login_btn"> -->
                                    <a  data-bs-toggle="modal"
                                    data-bs-target="#confirm" class="dropdown-item text-center btn btn-primary btn-pill"  href="">
                                       Book a Demo </a>
                                    <!-- </button> -->
       </li>
      <li class="mt-2">
                                    <!-- <button id="header_login_btn"> -->
                                    <a class="dropdown-item" href="/login">Login</a>
                                    <!-- </button> -->
     </li>
   
    </ul>
    <!-- <form class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
    </form> -->
  </div>
</nav>

              
                    <!-- Scroll Top Start -->
        <!-- <a href="#" class="scroll-top show" id="scroll-top">
            <i class="arrow-top icofont-bubble-up"></i>
            <i class="arrow-bottom icofont-bubble-up"></i>
        </a> -->
         <scroll-to-top-button></scroll-to-top-button>
        <!-- Scroll Top End -->

        </div>
    </div>
       

   

        <!-- modal of demo request-->
        <div
        class="modal fade"
        id="confirm"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-sm modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header bg-success">
              <h6 class="modal-title text-white">SCHEDULE A DEMO</h6>
              <button
                type="button"
                class="close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <form @submit.prevent="submitForm" class="css-txujy"><label for="email" class="css-tjq170  text-dark">Official Email:</label><input type="text" v-model="email" id="email" ref="userEmail" name="searchTerm" class="css-14lf73e" required>
                <p class="error-message" v-if="isInvalidEmail">Invalid email address.</p>
                    <label for="name" class="css-tjq170  text-dark">Name:</label><input id="name" type="text" v-model="name" ref="userName" name="searchTerm" class="css-14lf73e" required>
                <div class="css-uiem25"></div>
                <div class="css-1t6u4p9">
                    <!-- <div id="g-recaptcha" class="g-recaptcha" data-sitekey="6Ldl-KkUAAAAAEbiLOHHSKuQhQlB5pvWrhZOR4-E"
                        data-theme="light" data-type="image" data-size="normal" data-badge="bottomright"
                        data-tabindex="0">
                        <div style="width: 304px; height: 78px;">
                            <div><iframe title="reCAPTCHA"
                                    src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Ldl-KkUAAAAAEbiLOHHSKuQhQlB5pvWrhZOR4-E&amp;co=aHR0cHM6Ly93d3cuZ3JleXRoci5jb206NDQz&amp;hl=en&amp;type=image&amp;v=4rwLQsl5N_ccppoTAwwwMrEN&amp;theme=light&amp;size=normal&amp;badge=bottomright&amp;cb=z9tlrcrpape6"
                                    width="304" height="78" role="presentation" name="a-bcdm0y4d1ke8" frameborder="0"
                                    scrolling="no"
                                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"></iframe>
                            </div><textarea id="g-recaptcha-response" name="g-recaptcha-response"
                                class="g-recaptcha-response"
                                style="width: 250px; height: 40px; border: 1px solid rgb(193, 193, 193); margin: 10px 25px; padding: 0px; resize: none; display: none;"></textarea>
                        </div><iframe style="display: none;"></iframe>
                    </div> -->
                    <!-- <div class="css-avv687"><button class="css-xjapvm">Search</button></div> -->
                </div>
                <!-- <div class="css-rh9dea"></div> -->
               
                <div class="modal-footer justify-content-center">
                  <div v-if="isSending">
                     <p class="email-sending-mssg">Sending...</p>
                      <!-- Add your desired animations here -->
                      </div>
                 <div v-else-if="isSent">
                   <p class="email-sucess-mssg">Email sent successfully!</p>
                 </div>
                  <div v-else-if="sendingFailed">
                   <p class="email-error-mssg">Email sending failed!</p>
                </div>
                <button
                 v-else
                 v-show="showDemoButtons"
                    type="submit" id="submit" name="submit"
                    class="show-mssg btn btn-dark btn-hover-primary"
                    
                   
                  >
                    Schedule a Demo
                  </button>
                  <button v-show="showDemoButtons" type="button" class="demo-cancel btn btn-danger" data-bs-dismiss="modal">
                    Cancel
                  </button>
            </div>
            </form>
            </div>
           
          </div>
        </div>

   
        
      </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTopButton from './ScrollToTopButton.vue';
import axios from 'axios'; 

export default {
  // name: navBar,
  data() {
    return {
      email: "",
      name: "",
      isSending: false,
      isSent: false,
      sendingFailed: false,
      showDemoButtons: true
      // token: process.env.INTELLHRMS_API_KEY,
    };
  },
    components: {
     ScrollToTopButton,
    },
  mounted() {
    AOS.init({
      duration: 1100 // Set the default duration for all elements with data-aos-duration attribute
    });
  },
   computed: {
    isInvalidEmail() {
      return !this.isValidEmail(this.email) && this.email !== '';
    },
  },


  methods: {
     isValidEmail(email) {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

   submitForm() {
    const token = process.env.VUE_APP_INTELLHRMS_API_KEY;
    if (this.isValidEmail(this.email)){
    
    this.showDemoButtons = false;
     this.isSending = true;
     axios.post('https://demo.intellhrms.com/api/send-email', {
          name: this.name,
          email: this.email,
    },{
    headers: {
          Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          })
      .then(response => {
         this.isSending = false;
        this.isSent = true;
       
         setTimeout(() => {
        // Once the email is sent (simulated after 2 seconds), update the flags
        this.isSent = false;
        this.showDemoButtons = true;
        // Reset the form if needed
         this.clearForm()
      }, 1000);
       console.log('Email sent successfully',response);
      })
      .catch(error => {
        this.isSending = false;
        this.sendingFailed = true;
      
          setTimeout(() => {
        // Once the email is sent (simulated after 2 seconds), update the flags
        this.sendingFailed = false;
        this.showDemoButtons = true;
        // Reset the form if needed
         this.clearForm()
      }, 1000);
        console.error('Error sending email:', error);
      });
    }
  },

  clearForm(){
    this.email = "";
    this.name = "";
  }

}
};
</script>

<style scoped>
 .error-message {
  color:#ff3333;
  font-weight: 430;
  font-size: 13px;
  text-align: start;
  margin-top: 5px;
  }
  .email-sucess-mssg {
    color: green;
    font-family: sans-serif;
  }
  .email-sending-mssg {
    color: blue;
    font-family: sans-serif;
  }
  .show-mssg {
    text-align: left;
  }
  .email-error-mssg {
    color: red;
    font-family: sans-serif;
  }
  .demo-cancel {
    text-align: right;
  }
</style>