<template>
 <!-- Slider Section Start -->
    <div class="section slider-section">

        <!-- Swiper Container Start -->
        <div class="slider-container swiper-container animation-style-01">
            <div class="swiper-wrapper">

                <div class="swiper-slide inner">
                    <!-- Single Slider Start -->
                    <div class="slingle-slider image-box" data-bg-color="#000" data-bg-image="assets\images\home\hrmssystems.jpeg"
                       >
                       <img src="assets\images\home\hrmssystems.jpeg" alt="">
                        <!-- Slider Content Start -->
                        <div class="slider-content">
                            <img src="assets/images/home/intellhrms-02.png" alt="">
                            <!-- <h1>We Manage Your Resource Better</h1> -->
                            <a href="https://demo.dev.intellogicsoftware.net"
                                class="btn btn-outline-whited btn-outline-hover-primary mt-2"> Start a Free Trial </a>

                            <!-- <a href="employee-management.html" class="btn btn-outline-whited btn-outline-hover-primary">Let's Start </a> -->
                        </div>
                        <div></div>
                        <!-- Slider Content End -->

                    </div>
                    <!-- Single Slider End -->
                </div>

                <!-- <div class="swiper-slide"> -->
                <!-- Single Slider Start -->
                <!-- <div class="slingle-slider overlay" data-bg-color="#000" data-bg-image="assets/images/slider/slide-2.jpg"> -->

                <!-- Slider Content Start -->
                <!-- <div class="slider-content">
                            <h2 class="title">Triball Creative Agency</h2>
                            <a href="service-details.html" class="btn btn-outline-whited btn-outline-hover-primary">Let's Start </a>
                        </div> -->
                <!-- Slider Content End -->

                <!-- </div> -->
                <!-- Single Slider End -->
                <!-- </div> -->

            </div>
        </div>
        <!-- Swiper Container End -->

    </div>
    <!-- Slider Section End -->

     <div class="section section-margin-top">
        <div class="container">
            <div class="row">
                <div class="col-12"  data-aos-duration="1100">
                    <div class="section-title text-center">
                        <h2 class="title">What We Do</h2>
                        <!-- <span class="d-flex justify-content-center"></span> -->
                        <div class="section-title text-center">IntellHRMS help companies automate the HR processes, employee on-boarding
                        services and much more..We help you in Empowering your people and Business
                        Increasing Employee Satisfaction, Productivity, Quality of Data, Collaboration
                        Customer Experience, Company’s
                        Bottom-line and
                        Operational Efficiency and Receiving Real-Time Information</div>
                    </div>
                </div>
            </div>




            <div class="row">

                <div class="col-md-4 col-lg-3 col-xl-4 mb-4">
                    <div class="large-box">
                        <div class="card">
                            <div class="card-body">
                                <div>
                                    <img src="assets\images\home\icons8-payroll-48.png" alt="">
                                </div>
                                <h3>Payroll management</h3>
                                <p>Our industry redefining payroll system automates your payroll and saves time for
                                    everyone.</p>
                                <a href="payroll-management">Learn more <span class="learn-more-link">&#8594;</span></a>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="col-md-4 col-lg-3 col-xl-4 mb-4">
                    <div class="large-box">
                        <div class="card">
                            <div class="card-body">
                                <div class="dot dot-xl bg-aqua-light mb-30">
                                    <img src="assets\images\home\icons8-attach-resume-female-48.png" alt="">
                                </div>
                                <h3>Hiring & Onboarding</h3>
                                <p>An integrated hiring platform for teams to collaborate with recruiters and hire good
                                    talent.
                                </p>
                                <a href="employee-onboarding">Learn more <span class="learn-more-link">&#8594;</span></a>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="col-md-4 col-lg-3 col-xl-4 mb-4">
                    <div class="large-box">
                        <div class="card">
                            <div class="card-body">
                                <div class="dot dot-xl bg-aqua-light mb-30">
                                    <img src="assets\images\home\icons8-management-48 (1).png" alt="">
                                </div>
                                <h3>Employee management</h3>
                                <p>All your people information in one place to create a connected digital workplace.
                                    Maintain an up-to-date records</p>
                                <a href="employee-management">Learn more <span class="learn-more-link">&#8594;</span></a>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="col-md-4 col-lg-3 col-xl-4 mb-4">
                    <div class="large-box">
                        <div class="card">
                            <div class="card-body">
                                <div class="dot dot-xl bg-aqua-light mb-30">
                                    <img src="assets\images\home\icons8-leave-48.png" alt="">
                                </div>
                                <h3>Leave Management</h3>
                                <p>Allows your employees to take planned leaves for various reasons.(e.g. annual leave,
                                    sick leave, parental leave)</p>
                                <a href="leave-management">Learn more <span class="learn-more-link">&#8594;</span></a>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="col-md-4 col-lg-3 col-xl-4 mb-4">
                    <div class="large-box">
                        <div class="card">
                            <div class="card-body">
                                <div class="dot dot-xl bg-aqua-light mb-30">
                                    <img src="assets\images\home\icons8-asset-48.png" alt="">
                                </div>
                                <h3>Asset Management</h3>
                                <p>Systematic and strategic approach of tracking, maintaining, assets of employees</p>
                                <a href="asset-management">Learn more <span class="learn-more-link">&#8594;</span></a>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-md-4 col-lg-3 col-xl-4 mb-4">
                    <div class="large-box">
                        <div class="card">
                            <div class="card-body">
                                <div class="dot dot-xl bg-aqua-light mb-30">
                                    <img src="assets\images\home\icons8-calendar-48.png" alt="">
                                </div>
                                <h3>Attendance Management</h3>
                                <p>Track your employee time effectively and compensate on time while working remotely.
                                </p>
                                <a href="attendance-management">Learn more <span class="learn-more-link">&#8594;</span></a>
                            </div>
                        </div>
                    </div>
                </div>




                <!-- <div class="card">
            <h4><b>Card 1</b></h4>
            <p>Some text for Card 1.</p>
          </div></div> -->

                <!-- <div class="col">
    <div class="card">
      <h4><b>Card 2</b></h4>
      <p>Some text for Card 2.</p>
    </div>
     </div>
    <div class="col">
    <div class="card">
      <h4><b>Card 3</b></h4>
      <p>Some text for Card 3.</p>
    </div>
    </div>
    </div>
  </div> -->
                <!-- <div class="row m-b-n40">
                <div class="col-lg-4 col-sm-6 m-b-40" data-aos="fade-up" data-aos-duration="1100"> -->
                <!-- Single Service Start -->
                <!-- <div class="single-service">
                        <div class="service-icon">
                            <img src="assets/images/icon/service-1.png" alt="Service Icon">
                        </div>
                        <h4 class="title">Web Design</h4>
                        <p>Phasellus facilis lorem quis lacinia faucibus congue auctor tristique</p>
                        <a href="service-details.html" class="btn-link">Explore Now</a>
                    </div> -->
                <!-- Single Service End -->
                <!-- </div>
                <div class="col-lg-4 col-sm-6 m-b-40" data-aos="fade-up" data-aos-duration="1200"> -->
                <!-- Single Service Start -->
                <!-- <div class="single-service">
                        <div class="service-icon">
                            <img src="assets/images/icon/service-2.png" alt="Service Icon">
                        </div>
                        <h4 class="title">Photography</h4>
                        <p>Phasellus facilis lorem quis lacinia faucibus congue auctor tristique</p>
                        <a href="service-details.html" class="btn-link">Explore Now</a>
                    </div> -->
                <!-- Single Service End -->
                <!-- </div>
                <div class="col-lg-4 col-sm-6 m-b-40" data-aos="fade-up" data-aos-duration="1300"> -->
                <!-- Single Service Start -->
                <!-- <div class="single-service">
                        <div class="service-icon">
                            <img src="assets/images/icon/service-3.png" alt="Service Icon">
                        </div>
                        <h4 class="title">Digital Marketing</h4>
                        <p>Phasellus facilis lorem quis lacinia faucibus congue auctor tristique</p>
                        <a href="service-details.html" class="btn-link">Explore Now</a>
                    </div> -->
                <!-- Single Service End -->
                <!-- </div>
                <div class="col-lg-4 col-sm-6 m-b-40" data-aos="fade-up" data-aos-duration="1400"> -->
                <!-- Single Service Start -->
                <!-- <div class="single-service">
                        <div class="service-icon">
                            <img src="assets/images/icon/service-4.png" alt="Service Icon">
                        </div>
                        <h4 class="title">Business Management</h4>
                        <p>Phasellus facilis lorem quis lacinia faucibus congue auctor tristique</p>
                        <a href="service-details.html" class="btn-link">Explore Now</a>
                    </div> -->
                <!-- Single Service End -->
                <!-- </div>
                <div class="col-lg-4 col-sm-6 m-b-40" data-aos="fade-up" data-aos-duration="1500"> -->
                <!-- Single Service Start -->
                <!-- <div class="single-service">
                        <div class="service-icon">
                            <img src="assets/images/icon/service-5.png" alt="Service Icon">
                        </div>
                        <h4 class="title">Branding & Identity</h4>
                        <p>Phasellus facilis lorem quis lacinia faucibus congue auctor tristique</p>
                        <a href="service-details.html" class="btn-link">Explore Now</a>
                    </div> -->
                <!-- Single Service End -->
                <!-- </div>
                <div class="col-lg-4 col-sm-6 m-b-40" data-aos="fade-up" data-aos-duration="1600"> -->
                <!-- Single Service Start -->
                <!-- <div class="single-service">
                        <div class="service-icon">
                            <img src="assets/images/icon/service-6.png" alt="Service Icon">
                        </div>
                        <h4 class="title">Interior Design</h4>
                        <p>Phasellus facilis lorem quis lacinia faucibus congue auctor tristique</p>
                        <a href="service-details.html" class="btn-link">Explore Now</a>
                    </div> -->
                <!-- Single Service End -->
                <!-- </div>
            </div> -->
            </div>
        </div>
     </div>
        <!-- Service Section End -->

        <!-- Testimonial Section Start -->
        <!-- <div class="section section-padding overlay" data-bg-image="assets/images/banner/testimonial-banner.jpg" data-aos="fade-up" data-aos-duration="1100">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="swiper-container testimonial-carousel">
                        <div class="swiper-wrapper">

                            <div class="swiper-slide"> -->

        <!-- Single Testimonial wrapper Start -->
        <!-- <div class="single-testimonial-wrapper">
                                    <div class="testimonial-thumb">
                                        <img src="assets/images/icon/testimonial.png" alt="Testimonial Image">
                                    </div>
                                    <div class="testimonial-content">
                                        <p>Eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus</p>
                                        <span>Ethan Smith</span>
                                    </div>
                                </div> -->
        <!-- Single Testimonial wrapper End -->

        <!-- </div>

                            <div class="swiper-slide"> -->

        <!-- Single Testimonial wrapper Start -->
        <!-- <div class="single-testimonial-wrapper">
                                    <div class="testimonial-thumb">
                                        <img src="assets/images/icon/testimonial.png" alt="Testimonial Image">
                                    </div>
                                    <div class="testimonial-content">
                                        <p>Eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus</p>
                                        <span>Jhon Cristal</span>
                                    </div>
                                </div> -->
        <!-- Single Testimonial wrapper End -->

        <!-- </div> -->

        <!-- <div class="swiper-slide"> -->

        <!-- Single Testimonial wrapper Start -->
        <!-- <div class="single-testimonial-wrapper">
                                    <div class="testimonial-thumb">
                                        <img src="assets/images/icon/testimonial.png" alt="Testimonial Image">
                                    </div>
                                    <div class="testimonial-content">
                                        <p>Eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus</p>
                                        <span>Catherin Batty</span>
                                    </div>
                                </div> -->
        <!-- Single Testimonial wrapper End -->

        <!-- </div>

                        </div> -->

        <!-- Add Pagination -->
        <!-- <div class="swiper-pagination"></div> -->

        <!-- </div>
                </div>
            </div>
        </div>
    </div> -->
        <!-- Testimonial Section End -->

        <!-- Features Section Start -->
        <!-- <div class="section section-margin feature-section">
        <div class="container">
            <div class="row m-b-n40">
                <div class="col-xl-5 col-lg-6 m-b-40" data-aos="fade-up" data-aos-duration="1100">
                    <div class="feature-image-wrapper">
                        <span class="background-shape"></span>
                        <span class="background-layer">
								<img src="assets/images/feature/feature-layer.png" alt="Feature Image">
							</span>
                        <div class="feature-image">
                            <img src="assets/images/feature/feature-image.png" alt="Feature Image">
                        </div>
                    </div>
                </div>
                <div class=" col-lg-6 col-xl-6 offset-xl-1 align-self-center m-b-40">
                    <div class="section-title text-start" data-aos="fade-up" data-aos-duration="1100">
                        <h2 class="title m-b-30">Why you should choose us</h2>
                        <p class="m-auto">Voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur mag ni dolores eos qui ratione voluptatem sequi nesciunt porro quisq</p>
                    </div>
                    <div class="row m-b-n40">
                        <div class="col-sm-6 m-b-40" data-aos="fade-up" data-aos-duration="1300"> -->
        <!-- Single Service Start -->
        <!-- <div class="single-service">
                                <div class="service-icon">
                                    <img src="assets/images/icon/service-7.png" alt="Service Icon">
                                </div>
                                <h4 class="title">Powerful Web Builder</h4>
                                <p>Phasellus facilis lorem quis lacinia faucibus congue auctor tristique</p>
                            </div> -->
        <!-- Single Service End -->
        <!-- </div>
                        <div class="col-sm-6 m-b-40" data-aos="fade-up" data-aos-duration="1500"> -->
        <!-- Single Service Start -->
        <!-- <div class="single-service">
                                <div class="service-icon">
                                    <img src="assets/images/icon/service-1.png" alt="Service Icon">
                                </div>
                                <h4 class="title">Unique Design</h4>
                                <p>Phasellus facilis lorem quis lacinia faucibus congue auctor tristique</p>
                            </div> -->
        <!-- Single Service End -->
        <!-- </div>
                        <div class="col-sm-6 m-b-40" data-aos="fade-up" data-aos-duration="1700"> -->
        <!-- Single Service Start -->
        <!-- <div class="single-service">
                                <div class="service-icon">
                                    <img src="assets/images/icon/service-8.png" alt="Service Icon">
                                </div>
                                <h4 class="title">Reasonable Pricing</h4>
                                <p>Phasellus facilis lorem quis lacinia faucibus congue auctor tristique</p>
                            </div> -->
        <!-- Single Service End -->
        <!-- </div>
                        <div class="col-sm-6 m-b-40" data-aos="fade-up" data-aos-duration="1900"> -->
        <!-- Single Service Start -->
        <!-- <div class="single-service">
                                <div class="service-icon">
                                    <img src="assets/images/icon/service-9.png" alt="Service Icon">
                                </div>
                                <h4 class="title">Intime Delivery</h4>
                                <p>Phasellus facilis lorem quis lacinia faucibus congue auctor tristique</p>
                            </div> -->
        <!-- Single Service End -->
        <!-- </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
        <!-- Features Section End -->

        <!-- Gallery Section Start -->
        <!-- <div class="section gallery-section">
        <div class="container">
            <div class="row">
                <div class="section-title text-center" data-aos="fade-up" data-aos-duration="1100">
                    <h2 class="title m-b-30">Latest Works</h2>
                    <span class="thick-border pb-0 d-flex justify-content-center"></span>
                </div>
            </div>
            <div class="row">
                <div class="col-12" data-aos="fade-up" data-aos-duration="1500">
                    <div class="gallery-filter-nav bg-name-seashell">
                        <button class="filter-button active" data-filter="*">All</button>
                        <button class="filter-button" data-filter=".item1">Design</button>
                        <button class="filter-button" data-filter=".item2">Branding</button>
                        <button class="filter-button" data-filter=".item3">Typography</button>
                        <button class="filter-button" data-filter=".item4">Digital Art</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12" data-aos="fade-up" data-aos-duration="1800">
                    <div class="row gallery-filter-wrapper m-b-n50">
                        <div class="col-md-6 col-lg-4 m-b-50 filter-item item2 item3 item4"> -->
        <!-- Single Gallery Start -->
        <!-- <div class="single-gallery">
                                <div class="gallery-thumb">
                                    <a href="portfolio-details.html">
                                        <img class="fit-image" src="assets/images/portfolio/1.png" alt="Gallery Image">
                                    </a>
                                </div>
                            </div> -->
        <!-- Single Gallery End -->
        <!-- </div>
                        <div class="col-md-6 col-lg-4 m-b-50 filter-item item1 item3"> -->
        <!-- Single Gallery Start -->
        <!-- <div class="single-gallery">
                                <div class="gallery-thumb">
                                    <a href="portfolio-details.html">
                                        <img class="fit-image" src="assets/images/portfolio/2.png" alt="Gallery Image">
                                    </a>
                                </div>
                            </div> -->
        <!-- Single Gallery End -->
        <!-- </div>
                        <div class="col-md-6 col-lg-4 m-b-50 filter-item item1 item2 item3 item4"> -->
        <!-- Single Gallery Start -->
        <!-- <div class="single-gallery">
                                <div class="gallery-thumb">
                                    <a href="portfolio-details.html">
                                        <img class="fit-image" src="assets/images/portfolio/3.png" alt="Gallery Image">
                                    </a>
                                </div>
                            </div> -->
        <!-- Single Gallery End -->
        <!-- </div>
                    </div>
                </div>

            </div>
        </div>
    </div> -->
        <!-- Gallery Section End -->

        <!-- Video Banner Section Start -->
        <!-- <div class="section section-padding section-margin overlay" data-bg-image="assets/images/banner/video-banner.jpg">
        <div class="container">
            <div class="row">
                <div class="col-12"> -->

        <!-- Video Banner wrapper Start -->
        <!-- <div class="video-banner-wrapper">
                        <div class="video-banner-content" data-aos="fade-up" data-aos-duration="1100">
                            <p>Cumque nihil impedit quo minus id quod maxime possimus, omnis voluptas assumenda est</p>
                        </div>
                        <div class="video-btn" data-aos="fade-up" data-aos-duration="1600">
                            <a href="https://www.youtube.com/watch?v=v9nBysHSzhE" class="btn-play btn-video-popup video-popup">
                                <img src="assets/images/icon/video-icon.png" alt="Video Icon">
                            </a>
                        </div>
                    </div> -->
        <!-- Video Banner wrapper End -->

        <!-- </div>
            </div>
        </div>
    </div> -->
        <!-- Video Banner Section End -->

        <!-- Blog Section Start -->
        <!-- <div class="section section-margin-bottom">
        <div class="container">
            <div class="row m-b-n40">
                <div class="col-lg-4 m-b-40 align-self-center" data-aos="fade-up" data-aos-duration="1100">
                    <div class="about-content">
                        <h2 class="title">Latest Blog <br> post</h2>
                        <span></span>
                        <p>Voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur mag ni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro </p>
                        <a href="blog-details.html" class="btn btn-dark btn-hover-primary m-t-20">Explore Now</a>
                    </div>
                </div> -->
        <!-- <div class="col-lg-4 col-sm-6 m-b-40" data-aos="fade-up" data-aos-duration="1500"> -->
        <!-- Single Blog Wrapper Start -->
        <!-- <div class="single-blog-wrapper blog-box">
                        <div class="single-blog-thumb">
                            <a class="image" href="blog-details.html"><img src="assets/images/blog/blog-medium/1.png" alt="Blog Image"></a>
                        </div>
                        <div class="single-blog-content">
                            <div class="blog-meta">
                                <span>By John Smith, 20 May, 2021 / <a class="active" href="blog-details.html">Business</a></span>
                            </div>
                            <h4 class="title"><a href="blog-details.html">Design is inherently optimistic...</a></h4>
                            <p>Voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolor</p>
                        </div>
                    </div> -->
        <!-- Single Blog Wrapper End -->
        <!-- </div>
                <div class="col-lg-4 col-sm-6 m-b-40" data-aos="fade-up" data-aos-duration="2000"> -->
        <!-- Single Blog Wrapper Start -->
        <!-- <div class="single-blog-wrapper blog-box">
                        <div class="single-blog-thumb">
                            <a class="image" href="blog-details.html"><img src="assets/images/blog/blog-medium/2.png" alt="Blog Image"></a>
                        </div>
                        <div class="single-blog-content">
                            <div class="blog-meta">
                                <span class="blog-meta">By Jems Bond, 15 June, 2021 / <a href="blog-details.html">Corporate</a></span>
                            </div>
                            <h4 class="title"><a href="blog-details.html">Business Management System</a></h4>
                            <p>Voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolor</p>
                        </div>
                    </div> -->
        <!-- Single Blog Wrapper End -->
        <!-- </div>
            </div>
        </div>
    </div> -->
        <!-- Blog Section End -->

        <!-- Begin Brand Area -->
        <!-- <div class="section section-margin-bottom">
        <div class="container">
            <div class="row">
                <div class="col-lg-12" data-aos="fade-up" data-aos-duration="1100">
                    <div class="swiper-container brand-slider">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <a class="brand-item" href="#/">
                                    <img src="assets/images/brand/1-1.png" alt="Brand Image">
                                </a>
                            </div>
                            <div class="swiper-slide">
                                <a class="brand-item" href="#/">
                                    <img src="assets/images/brand/1-2.png" alt="Brand Image">
                                </a>
                            </div>
                            <div class="swiper-slide">
                                <a class="brand-item" href="#/">
                                    <img src="assets/images/brand/1-3.png" alt="Brand Image">
                                </a>
                            </div>
                            <div class="swiper-slide">
                                <a class="brand-item" href="#/">
                                    <img src="assets/images/brand/1-4.png" alt="Brand Image">
                                </a>
                            </div>
                            <div class="swiper-slide">
                                <a class="brand-item" href="#/">
                                    <img src="assets/images/brand/1-5.png" alt="Brand Image">
                                </a>
                            </div>
                            <div class="swiper-slide">
                                <a class="brand-item" href="#/">
                                    <img src="assets/images/brand/1-3.png" alt="Brand Image">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
        <!-- Brand Area End Here -->

        <!-- Contact Banner section Start -->
        <div class="section contact-banner-section  mb-50">
            <div>
                <img src="assets\images\slider\objectives3.jpg" alt="">
            </div>
            <div class="container-fluid contact-banner-container">
                <div class="row">
                    <div class="col-md-12 col-lg-9">
                        <div class="section-title m-b-20 pb-0" data-aos="fade-up" data-aos-duration="1100">
                            <h2 class="title">Contact Us</h2>
                        </div>
                        <div class="contact-form">
                            <form @submit.prevent="submitForm">
                                <div class="row m-b-n30">
                                    <div class="col-md-6 col-12 m-b-30" data-aos="fade-up" data-aos-duration="1100">
                                        <input type="text" v-model="name" placeholder="Name *" name="name" required>
                                    </div>
                                    <div class="col-md-6 col-12 m-b-30" data-aos="fade-up" data-aos-duration="1300">
                                        <input type="email" v-model="email" placeholder="Email *" name="email" required>
                                    </div>
                                    <div class="col-md-6 col-12 m-b-30" data-aos="fade-up" data-aos-duration="1500">
                                        <input type="text" v-model="subject" placeholder="Subject *" name="subject" required>
                                    </div>
                                    <!-- <div class="col-md-6 col-12 m-b-30" data-aos="fade-up" data-aos-duration="1700">
                                        <input type="text" placeholder="Phone *" name="Phone" required>
                                    </div> -->
                                    <div class="col-12 m-b-30" data-aos="fade-up" data-aos-duration="1900">
                                        <textarea cols="30" rows="5" class="textarea-item" name="message" v-model="message" placeholder="Message" required></textarea>
                                    </div>
                                     <div v-if="isSending">
                                         <p class="email-sending-mssg">Sending...</p>
                                         <!-- Add your desired animations here -->
                                          </div>
                                      <div v-else-if="isSent">
                                         <p class="email-sucess-mssg">Email sent successfully!</p>
                                         </div>
                                         <div v-else-if="sendingFailed">
                                         <p class="email-error-mssg">Email sending failed!</p>
                                         </div>
                                    <div v-else v-show="showSubmitButton" class="col-12 text-start m-b-30" data-aos="fade-up" data-aos-duration="2100">
                                        <button class="btn btn-dark btn-hover-primary">Send Now</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Contact Banner section End -->
</template>

<script>
import axios from 'axios'; 
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
      data() {
    return {
      email: "",
      name: "",
      subject: "",
      message: "",
      isSending: false,
      isSent: false,
      sendingFailed: false,
      showSubmitButton: true
      // token: process.env.INTELLHRMS_API_KEY,
    };
  },
  mounted() {
    AOS.init({
      duration: 1100 // Set the default duration for all elements with data-aos-duration attribute
    });
  },
    computed: {
    isInvalidEmail() {
      return !this.isValidEmail(this.email) && this.email !== '';
    },
  },

   methods: {
     isValidEmail(email) {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

    submitForm() {
     const token = process.env.VUE_APP_INTELLHRMS_API_KEY;
    if (this.isValidEmail(this.email)){
    
     this.showSubmitButton = false;
     this.isSending = true;
    this.isSending = true;
    axios.post('https://demo.intellhrms.com/api/contact-us', {
          name: this.name,
          email: this.email,
          subject: this.subject,
          message: this.message
    },{
    headers: {
          Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          })
      .then(response => {
         this.isSending = false;
        this.isSent = true;

        setTimeout(() => {
        // Once the email is sent (simulated after 2 seconds), update the flags
        this.isSent = false;
        this.showSubmitButton = true;
        // Reset the form if needed
         this.clearForm();
      }, 1000);
       console.log('Email sent successfully',response);
      })
      .catch(error => {
        this.isSending = false;
        this.sendingFailed = true;

        setTimeout(() => {
        // Once the email is sent (simulated after 2 seconds), update the flags
        this.sendingFailed = false;
        this.showSubmitButton = true;
        // Reset the form if needed
         this.clearForm();
      }, 1000);
       
        console.error('Error sending email:', error);
      });
    }
  },

  clearForm(){
    this.name = "";
    this.email = "";
    this.subject = "";
    this.message = "";
  },
   }
};
</script>

<style scoped>
 .error-message {
  color:#ff3333;
  font-weight: 430;
  font-size: 13px;
  text-align: start;
  margin-top: 5px;
  }
  .email-sucess-mssg {
    color: green;
    text-align: center;
    font-family: sans-serif;
  }
  .email-sending-mssg {
    color: blue;
    text-align: center;
    font-family: sans-serif;
  }
  .email-error-mssg {
    color: red;
    text-align: center;
    font-family: sans-serif;
  }
</style>
