// import Vue from 'vue';
// import App from './App.vue';
// import router from "./router";

// Vue.use(router);

// new Vue({
//   router,
//   render: h => h(App)
// }).$mount('#app');

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';



const app = createApp(App);
app.use(router);
app.mount('#app');




